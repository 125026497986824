import { connect } from 'react-redux'
import ToggleButton from 'react-toggle-button'

import { FormGroup } from 'reactstrap'

const ScoreCard = ({value, onChange, activeLabel, inactiveLabel, title, description, COLORS }) => (
    <FormGroup>
        <label className='form-control-label'>{title}</label>
        <ToggleButton
            activeLabel={activeLabel ? activeLabel : "Yes"}
            inactiveLabel={inactiveLabel ? inactiveLabel : "No"}
            colors={{
                activeThumb: {
                    base: 'red',
                },
                inactiveThumb: {
                    base: '#fff',
                },
                active: {
                    base: COLORS.green,
                    hover: 'rgb(177, 191, 215)',
                },
                inactive: {
                    base: COLORS.red,
                    hover: 'rgb(95,96,98)',
                }
                }}
            onToggle={() => onChange(!value)}
            value={value}
            animateThumbStyleHover={(n) => {
                return {
                boxShadow: `0 0 ${2 + 4*n}px rgba(0,0,0,.16),0 ${2 + 3*n}px ${4 + 8*n}px rgba(0,0,0,.32)`,
                }
            }} 
        />
    </FormGroup>
)



const mapStateToProps = state => {
    return {
        COLORS: state.config.COLORS,
    };
};

export default connect(mapStateToProps, '')(ScoreCard);