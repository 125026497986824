import { useCallback, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Input } from 'reactstrap'
import { connect } from 'react-redux'

import ToggleButton from 'react-toggle-button'

import Select1To10 from 'components/functional/inputs/Select1To10'
import Toggle from 'components/functional/inputs/Toggle'

const baseState = {
    score_overall: 0,
    score_overall_notes: '',
    score_empathy: 0,
    score_empathy_notes: '',
    score_knowledge: 0,
    score_knowledge_notes: '',
    
    correct_opening: true,
    correct_voicemail: true,
    correct_step_move: true,
    correct_terms: true,
    correct_notes: true,
}

const ScoreCard = ({ COLORS }) => {

    const [card, setCard] = useState(baseState)

    const onInputChange = useCallback((field, val) => {
        const state = JSON.parse(JSON.stringify(card))
        state[field] = val
        setCard(state)
    }, [card])

    return (
        <div>

            <Card className='custom-shadow-1'>
                <CardHeader>
                    <CardTitle className='mb-0'>Call Score Card</CardTitle>
                </CardHeader>

                <CardBody>
                    <Select1To10 
                        title="Overall Call Score"
                        value={card.score_overall}
                        onChange={(val) => onInputChange('score_overall', val)}
                    />
                    <FormGroup>
                        <label className='form-control-label'>Overall Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 40}}
                            value={card.score_overall_notes}
                            onChange={e => onInputChange('score_overall_notes', e.target.value)}
                        />
                    </FormGroup>


                </CardBody>

                <CardFooter>

                    <Select1To10 
                        title="Empathy Call Score"
                        value={card.score_empathy}
                        onChange={(val) => onInputChange('score_empathy', val)}
                    />

                    <FormGroup>
                        <label className='form-control-label'>Empathy Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 40}}
                            value={card.score_empathy_notes}
                            onChange={e => onInputChange('score_empathy_notes', e.target.value)}
                        />
                    </FormGroup>

                </CardFooter>

                <CardFooter>

                    <Select1To10 
                        title="Material Knowledge Score"
                        value={card.score_knowledge}
                        onChange={(val) => onInputChange('score_knowledge', val)}
                    />

                    <FormGroup>
                        <label className='form-control-label'>Knowledge Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 40}}
                            value={card.score_knowledge_notes}
                            onChange={e => onInputChange('score_knowledge_notes', e.target.value)}
                        />
                    </FormGroup>

                </CardFooter>

                <CardFooter>
                    <Toggle 
                        title="Handled Call Opening Correctly"
                        value={card.correct_opening}
                        onChange={(val) => onInputChange('correct_opening', val)}
                    />
                </CardFooter>
                
                <CardFooter>
                    <Toggle 
                        title="Left Voicemail Correctly (If Applicable)"
                        value={card.correct_voicemail}
                        onChange={(val) => onInputChange('correct_voicemail', val)}
                    />
                </CardFooter>

                <CardFooter>
                    <Toggle 
                        title="Moved Steps / Used Tags Correctly (If Applicable)"
                        value={card.correct_step_move}
                        onChange={(val) => onInputChange('correct_step_move', val)}
                    />
                </CardFooter>

                <CardFooter>
                    <Toggle 
                        title="Used Correct Industry Terms"
                        value={card.correct_terms}
                        onChange={(val) => onInputChange('correct_terms', val)}
                    />
                </CardFooter>

                <CardFooter>
                    <Toggle 
                        title="Documented Call Correctly Via Notes"
                        value={card.correct_notes}
                        onChange={(val) => onInputChange('correct_notes', val)}
                    />
                </CardFooter>

                <CardFooter className='text-right'>
                    <button className='btn btn-sm btn-success'>Submit Score Card <i className="fas fa-paper-plane ml-2" /></button>
                </CardFooter>
            </Card>

            

         
        </div>
    )
}



const mapStateToProps = state => {
    return {
        COLORS: state.config.COLORS,
    };
};

export default connect(mapStateToProps, '')(ScoreCard);