import { connect } from 'react-redux';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown } from "reactstrap";

import formatObject from 'utils/formatObject';
import A from 'components/markup/links/A';

import { Tooltip } from 'react-tooltip';

import hasPrivilege from 'utils/hasPrivilege';

const NavbarDesktopHeaderTop = ({selected_division, divisions, toggleSidenav, onSetDivision, viewing_user, DOMAINS}) => {

    const canWriteMatters = hasPrivilege('MATTERS.WRITE') && hasPrivilege('VIEW.APP_DASHBOARD.MATTERS')
    const canWriteContacts = hasPrivilege('CONTACTS.WRITE') && hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS')

    return (

        <Container  fluid className='bg-white border-bottom'>

            <Row className="align-items-center py-2">

                <Col md={5} xs="3" className="align-self-center">
                    <ul className='d-block d-md-none'>
                        <li>
                            <i onClick={toggleSidenav} style={{fontSize: 20}} className="fa-solid fa-bars display- cursor-pointer"></i>
                        </li>
                    </ul>
                </Col>

                <Col md={7} xs="9"  className="text-right header-right align-self-center">

                    <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>

                        <ul>
                            <li>

                                <UncontrolledDropdown >
                                    <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                        {selected_division.name ? selected_division.name : "All Divisions"} <i className="fas fa-caret-down ml-2 px-0 " />
                                    </DropdownToggle>

                                    <DropdownMenu end>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">Selected Division</h6>
                                            </DropdownItem>

                                            {/* <DropdownItem onClick={() => onSetDivision({_id: 'all'})} >
                                                All Divisions
                                            </DropdownItem> */}

                                            {divisions.map(division => (
                                                <DropdownItem key={division._id} onClick={() => onSetDivision(division)} >
                                                    {division.name}
                                                </DropdownItem>
                                            ))}
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </li>


                            <li className='pl-3 d-none d-md-inline-block'>
                                <A href={DOMAINS.APP}>
                                    <i 
                                        id="archk-tooltip-nav-dashboard"
                                        data-tooltip-content={ `Main Dashboard`}
                                        data-tooltip-delay-show={1500}
                                        data-tooltip-variant={'success'}
                                        className="fas fa-door-open text-info navbar-block cursor-pointer" 
                                    />
                                    <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-nav-dashboard" />
                                </A>
                            </li>

                            
                            {canWriteMatters || canWriteContacts  ? (
                                <li className='d-none d-md-inline-block'>
                                    <UncontrolledDropdown >
                                        
                                        <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            <i 
                                                id="archk-tooltip-nav-settings"
                                                data-tooltip-content={ `Add New`}
                                                data-tooltip-delay-show={1500}
                                                data-tooltip-variant={'success'}
                                                className="fas fa-plus text-muted navbar-block cursor-pointer" 
                                            />
                                            <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-nav-settings" />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">

                                                <DropdownItem  className="noti-title" header tag="div">
                                                    <h5 className="text-overflow m-0">Add Something</h5>
                                                </DropdownItem>

                                                {canWriteMatters ? (
                                                    <DropdownItem tag={A} href={`${DOMAINS.APP}/matters/create`} >
                                                        Matter
                                                    </DropdownItem>
                                                ) : ''}

                                                {canWriteContacts ? (
                                                    <DropdownItem tag={A} href={`${DOMAINS.APP}/contacts/create`} >
                                                        Client
                                                    </DropdownItem>
                                                ) : ''}

                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                            ) : ''}             

                            <li className='pl-3'>
                                <UncontrolledDropdown>
                                    <DropdownToggle className="nav-link pr-0 ml-3 cursor-pointer font-weight-bold text-capitalize" color="" tag="a">

                                    {formatObject(viewing_user).name() } <i className="fas fa-caret-down ml-2  px-0 " />
                                        
                                    </DropdownToggle>

                                    <DropdownMenu end>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h5 className="text-overflow m-0">My Account</h5>
                                            </DropdownItem>

                                            <DropdownItem tag={A} href={`${DOMAINS.APP}/settings`} >
                                                Settings
                                            </DropdownItem>
                                            
                                            <DropdownItem href={`${DOMAINS.AUTH}/logout`} >
                                                <span>Logout</span>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </li>
                        </ul>

                    </Nav>

                </Col>

            </Row>

        </Container>

    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
        divisions: state.divisions,
        socket: state.socket,

	};
};

export default connect(mapStateToProps, '')(NavbarDesktopHeaderTop);
