import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import moment from 'moment';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import DatePicker from 'react-datepicker';
import Circle from "components/markup/loading/Circle";
import formatText from 'utils/formatText';

import { toast } from 'react-toastify';

import api from 'api';

const SupervisorPhoneCalls = ({DOMAINS, selected_division}) => {

    const [data, setData] = useState(null)

    const [sort, setSort] = useState('total')
    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));

    const fetchData = useCallback(async () => {
        setData(null)

        const result = await api._supervisor.phoneCallsUsers(selected_division._id, { 
            start: parseInt(moment(start).format('X')),
            end: parseInt(moment(end).endOf('day').format('X'))
        })

        if(!result.success) return toast.error(`Could not load results, please try again`)
        return setData(result.data)
    }, [selected_division._id, start, end])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const onSort = useCallback((prop) => {
        setSort(prop)
        const d = JSON.parse(JSON.stringify(data));
        d.sort((a, b) => a[prop] > b[prop] ? -1 : 1)
        setData(d)
    }, [data])

    const title = 'User Phone Calls'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            // description="All Calls"
            leftColSize={3}
            actionComponent={(
               <div>
                <Link to="/phone_calls_user/division" className="btn btn-success" > View Division Overview <i className="fas fa-link ml-2" /></Link>
               </div>
            )}
        />

        <Container fluid className="mb-3">
            <Row>
                <Col md={4}>
                   
                </Col>
                
                <Col md={4}>
                    <p className="text-sm mb-0">Select Start Date</p>
                    <DatePicker 
                        selected={start} 
                        onChange={(date) => setStart(date)} 
                    />
                </Col>
                <Col md={4}>
                    <p className="text-sm mb-0">Select End Date</p>
                    <DatePicker 
                        selected={end} 
                        onChange={(date) => setEnd(date)} 
                    />
                </Col>
            
            </Row>
        </Container>

        <Container fluid>

            {!data ? <div className="py-6"><Circle /></div> : (
                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">User Calls Overview</CardTitle>
                    </CardHeader>

                    {data.length ? (
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            User
                                        </th>
                                        <th onClick={() => onSort('total')} className={sort === 'total' ? 'text-success cursor-pointer' : 'cursor-pointer'}>
                                            Total Calls
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>
                                        <th onClick={() => onSort('total_duration')} className={sort === 'total_duration' ? 'text-success cursor-pointer' : 'cursor-pointer'}>
                                            Total Duration
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>
                                        <th onClick={() => onSort('total_duration_avg')} className={sort === 'total_duration_avg' ? 'text-success cursor-pointer' : 'cursor-pointer'}>
                                            AVG Duration
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>

                                        <th onClick={() => onSort('inbound')} className={sort === 'inbound' ? 'text-success cursor-pointer' : 'cursor-pointer'}>
                                            Inbound Calls
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>
                                        <th onClick={() => onSort('inbound_duration_avg')} className={sort === 'inbound_duration_avg' ? 'text-success cursor-pointer' : 'cursor-pointer'}>
                                            AVG Inbound Duration
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>

                                        <th onClick={() => onSort('outbound')} className={sort === 'outbound' ? 'text-success cursor-pointer' : 'cursor-pointer'}>
                                            Outbound Calls
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>
                                        <th onClick={() => onSort('outbound_duration_avg')} className={sort === 'outbound_duration_avg' ? 'text-success cursor-pointer' : 'cursor-pointer'} >
                                            AVG Outbound Duration 
                                            <i  style={styles.sort} className="fas fa-sort-down ml-2" />
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data.map(d => (
                                        <tr key={d.user}>
                                            <td>{d.name}</td>
                                            <td>{d.total}</td>
                                            <td>{formatText(d.total_duration).secondsToTime(null, true)}</td>
                                            <td>{d.total_duration_avg ? formatText(d.total_duration_avg).secondsToTime(null, true) : '-'}</td>
                                         
                                            <td>{d.inbound}</td>
                                            <td>{d.inbound_duration_avg ? formatText(d.inbound_duration_avg).secondsToTime(null, true) : '-'}</td>
                                            
                                            <td>{d.outbound}</td>
                                            <td>{d.outbound_duration_avg ? formatText(d.outbound_duration_avg).secondsToTime(null, true) : '-'}</td>
                                            
                                            <td className="text-right">
                                                <A href={`/phone_calls_user/${d.user}`} className="btn btn-sm btn-success">View</A>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <CardBody>
                            <p className="text-sm mb-0">Could not find any data for the times selected.</p>
                        </CardBody>
                    )}
                </Card>
            )}
    

        </Container>

        </>
    )

}

const styles = {
    sort: {
        position: 'relative',
        top: -3
    }
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(SupervisorPhoneCalls);
