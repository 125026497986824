import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const MattersAll = ({callLog}) => (
    <Card className="card-color card-primary table-fixed custom-shadow-1">

            <CardHeader className={`bg-gradient-${callLog.ai_rating >= 8 ? 'success' : callLog.ai_rating >= 5 ? 'info' : callLog.ai_rating === 0 ? 'purple' : 'danger'} text-white`}>
                <CardTitle className="mb-0">Quality Rating</CardTitle>
            </CardHeader>
            <CardBody className="">
                <p className="mb-0 text-sm">{callLog.ai_rating ? callLog.ai_rating : '-'}</p>
            </CardBody>
            
            <CardHeader className="bg-gradient-info text-white">
                <CardTitle className="mb-0">Call Summary</CardTitle>
            </CardHeader>
            <CardBody className="">
                <p className="mb-0 text-sm">{callLog.ai_summary ? callLog.ai_summary : <span className="text-warning">This Call Does Not Have An Available Summary</span>}</p>
            </CardBody>

            <CardHeader className="bg-gradient-info text-white">
                <CardTitle className="mb-0">Full Transcription</CardTitle>
            </CardHeader>
            <CardBody className="">
                <p className="mb-0 text-sm">{callLog.ai_transcription ? callLog.ai_transcription : <span className="text-warning">This Call Does Not Have An Available Summary</span>}</p>
            </CardBody>

            <CardHeader className="bg-gradient-info text-white">
                <CardTitle className="mb-0">Derived Call Category</CardTitle>
            </CardHeader>
            <CardBody className="">
                <p className="mb-0 text-sm text-capitalize">{callLog.ai_category ? callLog.ai_category.replace('_', ' ') : 'other'}</p>
            </CardBody>

        </Card>
)

export default MattersAll