import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Row, CardFooter } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';
import DatePicker from 'react-datepicker';
import Circle from 'components/markup/loading/Circle';
import SearchCollections from "components/system/Search/Collections";

import { toast } from 'react-toastify';

import api from 'api';
import moment from 'moment';

import { CSVLink } from "react-csv";


const Payments = ({selected_division}) => {

    const [ loading, setLoading ] = useState(true);

    const [ csvData, setCSVData ] = useState(null);
    const [ users, setUsers ] = useState([]);
    const [ workflow, setWorkflow ] = useState('');
    const [ workflowRole, setWorkflowRole ] = useState('');

    const [ start, setStart ] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [ end, setEnd ] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));

//     const today = new Date();
// const thirtyDaysAgo = new Date();
// thirtyDaysAgo.setDate(today.getDate() - 30);
    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)

    const onAddUser = useCallback((u) => {
        const _users = JSON.parse(JSON.stringify(users))
        if(!_users.includes(u)) _users.push(u)
        setUsers(_users)
    }, [users])
    
    const onRemoveUser = useCallback((u) => {
        let _users = JSON.parse(JSON.stringify(users))
        _users = _users.filter(id => id !== u)
        setUsers(_users)
    }, [users])

    const onSetStart = useCallback((s) => {
        const _start = parseInt(moment(s).startOf('day').format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newEnd = moment(s).endOf('month').toDate();
            setEnd(newEnd)
        }
        setStart(s)
    }, [end])
    
    const onSetEnd = useCallback((e) => {
        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(e).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newStart = moment(e).startOf('month').toDate();
            setStart(newStart)
        }
        setEnd(e)
    }, [start])

    // helper for this.downloadCSV and this.query
    const query = useCallback(() => new Promise (async resolve => {
        if(!workflow) return toast.info(`A workflow must be selected`)
        if(!workflowRole) return toast.info(`A workflow role must be selected`)
        if(!users.length) return toast.info(`At least one user must be selected`)

        const _start = parseInt(moment(start).startOf('day').format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_start > _end) return toast.info(`Search start date cannot be greater than end date.`)
        if(_end > (_start + ( 86400 * 32 ))) return toast.info(`Search range must span no more than 1 month`)


        setLoading(true)
        const query = await api._supervisor.userLinearity(selected_division._id, {
            start: _start,
            end: _end,
            workflow,
            workflow_role: workflowRole,
            users
        })
        setLoading(false)

        if(!query.data) return toast.info(query.message)

        const _csvData = []
        query.data.forEach(d => {
            let obj = { 
                user: d.user, 
                name: d.name,
                start: moment(start).startOf('day').format('MM/DD/YYYY h:mm A'),
                end: moment(end).endOf('day').format('MM/DD/YYYY h:mm A'),
            }

            d.stages.forEach(dd => {
                obj[dd.name] = dd.num
            })

            _csvData.push(obj)
        })

        setCSVData(_csvData)
        return setData(query.data)
        

    }), [workflow, workflowRole, users, start, end, selected_division._id])


    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'User Effectiveness Reports'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            // description="All Calls"
            leftColSize={6}
            actionComponent={(
               <div></div>
            )}
        />


        <Container fluid>

           
          <Row>

            <div className="col-auto" style={{width: 250}}>
                <Card className="custom-shadow-1">
                    <CardHeader>
                        <CardTitle className="mb-0 text-uppercase">Analytics Filter</CardTitle>
                    </CardHeader>
                
                    <CardBody>
                
                        <FormGroup>
                            <p className="text-sm mb-0">Matter Creation Start Date</p>
                            <DatePicker 
                                selected={start} 
                                onChange={(date) => onSetStart(date)} 
                            />
                        </FormGroup>

                        <FormGroup>

                            <p className="text-sm mb-0">Matter Creation End Date</p>
                            <DatePicker 
                                selected={end} 
                                onChange={(date) => onSetEnd(date)} 
                            />
                        </FormGroup>

                       

                        <hr />

                        <SearchCollections
                            collection={'workflows'}
                            title="Select Workflow"
                            placeholder="Search..."
                            value={workflow}
                            onChange={(obj) => setWorkflow(obj.value)}
                            filter={{
                                division: selected_division._id,
                            }}
                        /> 

                        {workflow ? (
                            <div>
                                <SearchCollections
                                    collection={'workflow_roles'}
                                    title="Workflow Role"
                                    placeholder="Search..."
                                    disabled={false}
                                    value={workflowRole}
                                    onChange={(obj) => setWorkflowRole(obj.value)}
                                    filter={{
                                        workflow,
                                    }}
                                /> 

                                <hr />

                                <SearchCollections
                                    collection={'users'}
                                    title="Select User(s)"
                                    placeholder="Search..."
                                    disabled={false}
                                    hideSelected={true}
                                    onChange={(obj) => onAddUser(obj.value)}
                                    filter={{
                                        division: selected_division._id,
                                    }}
                                /> 

                                {users.map(u => (
                                    <Badge key={u} color="warning   " className='my-2 mx-0 d-block text-left'>
                                        <ObjectFinder collection="users" _id={u} /> 
                                        <i onClick={() => onRemoveUser(u)} className="fas fa-times pl-3 pr-2 cursor-pointer float-right" />
                                    </Badge>
                                ))} 

                                <hr />

                                <button onClick={query} className='btn btn-block btn-success'>
                                    <i className="fas fa-chart-pie mr-2" /> Run Report
                                </button>

                                <div className='pb-3' />
                            </div>
                        ) : null }
                       

                        </CardBody>
                    </Card>
                
                </div>

                <Col>

                    {!data ? (
                        <div className="text-center mt-4"><i className="fas fa-info-circle text-info mr-2" /> Select a workflow and user list to search.</div>
                    ) : loading ? <div className="py-6"><Circle /></div> : data && data[0] ? (
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Results</CardTitle>
                            </CardHeader>

                            <div className="table-responsive ">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            {data[0].stages.map((stage, i) => (
                                                <th key={i}>{stage.name}</th>
                                            ))}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.map(d => (
                                            <tr key={d.user}>
                                                <td>{d.name}</td>
                                                {d.stages.map((stage, i) => (
                                                    <td key={i}>
                                                        {stage.num}
                                                        {i !== 0 ? (
                                                            <div><small>{((stage.num / d.stages[0].num) * 100).toFixed(2)}%</small></div>
                                                        ) : (
                                                            <div><small>100%</small></div>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {csvData ? (
                                <CardFooter className="text-right">
                                    <CSVLink
                                        data={csvData} 
                                        filename={"effectiveness.csv"}
                                        asyncOnClick={true} 
                                    >
                                        <button className="btn btn-success btn-sm mt-2">Export To Csv</button>
                                    </CSVLink>
                                </CardFooter>
                            ) : ''}
                        </Card>
                    ) : (
                        <p className="text-center mb-0">No Data Found</p>
                    )}

                </Col>
            </Row>
    
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(Payments);
