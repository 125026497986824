import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Container, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import DatePicker from 'react-datepicker';
import Circle from "components/markup/loading/Circle";
import formatText from 'utils/formatText';
import ObjectFinder from 'components/system/Objects/Finder';

import { toast } from 'react-toastify';

import api from 'api';

import PieArea from 'components/charts/PieArea';


const SupervisorPhoneCalls = ({match, selected_division}) => {

    const [data, setData] = useState(null)

    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));

    const fetchData = useCallback(async () => {
        setData(null)

        const result = await api._supervisor.phoneCallsUser(selected_division._id, match.params.user, { 
            start: parseInt(moment(start).format('X')),
            end: parseInt(moment(end).endOf('day').format('X'))
        })

        if(!result.success) return toast.error(`Could not load results, please try again`)
        return setData(result.data)
    }, [selected_division._id, match.params.user, start, end])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const title = 'User Phone Calls'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={(
                <ObjectFinder collection="users" _id={match.params.user} />
            )}
            leftColSize={6}
            actionComponent={(
                <div>
                    <Row>

                        <Col md={6}>
                            <p className="text-sm mb-0">Select Start Date</p>
                            <DatePicker 
                                selected={start} 
                                onChange={(date) => setStart(date)} 
                            />
                        </Col>
                        <Col md={6}>
                            <p className="text-sm mb-0">Select End Date</p>
                            <DatePicker 
                                selected={end} 
                                onChange={(date) => setEnd(date)} 
                            />
                        </Col>

                    </Row>
                </div>
            )}
        />


        <Container fluid>

            {!data ? <div className="py-6"><Circle /></div> : (
                <div>

                    <Row className="mb-4">
                        <Col md={6}>
                            <PieArea 
                                title="Inbound / Outbound Total"
                                classNames="mb-0 border"
                                type="pie"
                                labels={['Inbound', 'Outbound']}
                                dataset={[data.inbound, data.outbound]}
                            />
                        </Col>
                        <Col md={6}>
                            <PieArea 
                                classNames="mb-0 border"
                                title="AVG Duration Per Category"
                                type="pie"
                                labelType="time"
                                labels={data.docs.workflow_step_categories.map(d => d.identifier ? d.identifier : 'Unassigned')}
                                dataset={data.docs.workflow_step_categories.map(d => d.duration)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <Card className='text-center text-uppercase'>
                                <CardHeader>
                                    <CardTitle className='mb-0'>All Calls</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>AVG: {formatText(data.total_avg).secondsToTime(null, true)}</h4>
                                    <p className='text-sm mb-0'>Unique: {data.total}</p>
                                </CardBody>
                                <CardFooter>
                                    <p className='text-sm mb-0'>Total: {formatText(data.total_duration).secondsToTime()}</p>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className='text-center text-uppercase'>
                                <CardHeader>
                                    <CardTitle className='mb-0'>Inbound Calls</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>AVG: {formatText(data.inbound_avg).secondsToTime(null, true)}</h4>
                                    <p className='text-sm mb-0'>Unique: {data.inbound}</p>
                                </CardBody>
                                <CardFooter>
                                    <p className='text-sm mb-0'>Total: {formatText(data.inbound_duration).secondsToTime()}</p>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className='text-center text-uppercase'>
                                <CardHeader>
                                    <CardTitle className='mb-0'>Outbound Calls</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>AVG: {formatText(data.outbound_avg).secondsToTime(null, true)}</h4>
                                    <p className='text-sm mb-0'>Unique: {data.outbound}</p>
                                </CardBody>
                                <CardFooter>
                                    <p className='text-sm mb-0'>Total: {formatText(data.outbound_duration).secondsToTime()}</p>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className='text-center text-uppercase'>
                                <CardHeader>
                                    <CardTitle className='mb-0'>Contacts</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>AVG: {formatText(data.contact_avg).secondsToTime(null, true)}</h4>
                                    <p className='text-sm mb-0'>Unique: {data.contact_total}</p>
                                </CardBody>
                                <CardFooter>
                                    <p className='text-sm mb-0'>Total: {formatText(data.contact_duration).secondsToTime()}</p>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className='text-center text-uppercase'>
                                <CardHeader>
                                    <CardTitle className='mb-0'>Step Categories</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>AVG: {formatText(data.workflow_step_category_avg).secondsToTime(null, true)}</h4>
                                    <p className='text-sm mb-0'>Unique: {data.workflow_step_category_total}</p>
                                </CardBody>
                                <CardFooter>
                                    <p className='text-sm mb-0'>Total: {formatText(data.workflow_step_category_duration).secondsToTime()}</p>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className='text-center text-uppercase'>
                                <CardHeader>
                                    <CardTitle className='mb-0'>Distinct Steps</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>AVG: {formatText(data.workflow_step_avg).secondsToTime(null, true)}</h4>
                                    <p className='text-sm mb-0'>Unique: {data.workflow_step_total}</p>
                                </CardBody>
                                <CardFooter>
                                    <p className='text-sm mb-0'>Total: {formatText(data.workflow_step_duration).secondsToTime()}</p>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    <Card>
                        <CardHeader>
                            <CardTitle className='mb-0'>Workflow Step Categories</CardTitle>
                        </CardHeader>

                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Total Duration</th>
                                        <th>Unique</th>
                                        <th>AVG Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.docs.workflow_step_categories.map((d, i) => (
                                        <tr key={i}>
                                            <td>{d.identifier ? d.identifier : <b className='text-danger'>Unassigned</b>}</td>
                                            <td>{formatText(d.duration).secondsToTime(null, true)}</td>
                                            <td>{d.total}</td>
                                            <td>{formatText(d.avg).secondsToTime(null, true)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle className='mb-0'>Workflow Steps</CardTitle>
                        </CardHeader>

                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Step</th>
                                        <th>Total Duration</th>
                                        <th>Unique</th>
                                        <th>AVG Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.docs.workflow_steps.map((d, i) => (
                                        <tr key={i}>
                                            <td>{d.identifier ? d.identifier : <b className='text-danger'>Unassigned</b>}</td>
                                            <td>{formatText(d.duration).secondsToTime(null, true)}</td>
                                            <td>{d.total}</td>
                                            <td>{formatText(d.avg).secondsToTime(null, true)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle className='mb-0'>Contacts</CardTitle>
                        </CardHeader>

                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Contact</th>
                                        <th>Total Duration</th>
                                        <th>Unique</th>
                                        <th>AVG Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.docs.contacts.map((d, i) => (
                                        <tr key={i}>
                                            <td>{d.identifier ? d.identifier : <b className='text-danger'>Unassigned</b>}</td>
                                            <td>{formatText(d.duration).secondsToTime(null, true)}</td>
                                            <td>{d.total}</td>
                                            <td>{formatText(d.avg).secondsToTime(null, true)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card>

                </div>

            )}
    

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(SupervisorPhoneCalls);
