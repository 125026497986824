import { Col, FormGroup, Row } from 'reactstrap'

const Select1To10 = ({ title, description, value, onChange }) => (
    <FormGroup>
        <label className='form-control-label mb-3'>{title}</label>
        {description ? <p className='text-sm mt--3 mb-3'>{description}</p> : ''}
        <div className='archk-select-1-10 my-2'>
            <Row>


                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 1 ? 'selected' : ''}`}  onClick={() => onChange(1)}>
                    1
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 2 ? 'selected' : ''}`}  onClick={() => onChange(2)}>
                    2
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 3 ? 'selected' : ''}`}  onClick={() => onChange(3)}>
                    3
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 4 ? 'selected' : ''}`}  onClick={() => onChange(4)}>
                    4
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 5 ? 'selected' : ''}`}  onClick={() => onChange(5)}>
                    5
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 6 ? 'selected' : ''}`}  onClick={() => onChange(6)}>
                    6
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 7 ? 'selected' : ''}`}  onClick={() => onChange(7)}>
                    7
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 8 ? 'selected' : ''}`}  onClick={() => onChange(8)}>
                    8
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 9 ? 'selected' : ''}`}  onClick={() => onChange(9)}>
                    9
                    </span>
                </Col>
                <Col> 
                    <span className={`archk-select-1-10-circle ${value === 10 ? 'selected' : ''}`}  onClick={() => onChange(10)}>
                    10
                    </span>
                </Col>

            </Row>

        </div>
    </FormGroup>
)

export default Select1To10