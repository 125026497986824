import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';


import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';

import { formatCurrency } from 'utils/currency';


const DelinquentMatters = ({DOMAINS, selected_division}) => {

    const [ loading, setLoading ] = useState(true);

    const [ sort, setSort ] = useState('oldest');
    const [ range, setRange ] = useState('0-30');
    const [ runTime, setRunTime ] = useState('all');
    const [ failedCount, setFailedCount ] = useState('0');
    const [ failedAmount, setFailedAmount ] = useState('0');
  
    const [ failedCountPlaceholder, setFailedCountPlaceholder ] = useState('0');
    const [ failedAmountPlaceholder, setFailedAmountPlaceholder ] = useState('0');

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV, useLoader) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.sort = { delinquent_since: sort === 'oldest' ? 1 : -1 }

        const day = 86400;

        let delinquent_since
        let delinquent_end;


        if(range !== 'all') {
            const endOfDay = parseInt(moment().endOf('day').format('X'))

            if(range === '0-30') {
                delinquent_end = endOfDay;
                delinquent_since = { $lt: delinquent_end, $gt: delinquent_end - ( 30 * day ) }

            } else if(range === '31-60') {
                delinquent_end = endOfDay - ( 31 * day );
                delinquent_since = { $lt: delinquent_end, $gt: delinquent_end - ( 29 * day ) }

            } else if(range === '61-90') {
                delinquent_end = endOfDay - ( 61 * day );
                delinquent_since = { $lt: delinquent_end, $gt: delinquent_end - ( 29 * day ) }
            } else if(range === '91-180') {
                delinquent_end = endOfDay - ( 91 * day );
                delinquent_since = { $lt: delinquent_end, $gt: delinquent_end - ( 89 * day ) }

            } else if(range === '180+') {
                delinquent_end = endOfDay - ( 180 * day );
                delinquent_since = { $lt: delinquent_end }
            }
            
        }

        if(delinquent_since) params.filter.delinquent_since = delinquent_since

        if(runTime !== 'all') params.filter.run_time = runTime;
        if(failedCount !== '0') params.filter.delinquent_payments = { $gt: parseFloat(failedCount) };
        if(failedAmount !== '0') params.filter.delinquent_amount = { $gt: parseFloat(failedAmount) };

        if(useLoader)setLoading(true)
        const query = await api._supervisor.delinquentMatters(selected_division._id, { ...params, isCSV })
        setLoading(false)

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents, overview: query.data.overview })
        setErr(query.message)
    }), [sizePerPage, sort, range, runTime, failedCount, failedAmount, selected_division._id])

    useEffect(() => {
        query(null, null, true)
        // eslint-disable-next-line
    }, [sort, range, runTime, failedCount, failedAmount])


    const columns = [
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <A href={`${DOMAINS.APP}/billing/-/${row.matter}?tab=recurring`}>
                            <ObjectFinder collection="matters" _id={row.matter} />
                        </A>
                    </div>
                )
            },
        },
       
        {
            dataField: "amount",
            text: 'Amount',
            headerStyle: { },

            formatter: (cell, row) => row.name === 'no data' ? '' : formatCurrency(row.amount)
        },
        {
            dataField: "recurring_balance",
            text: 'Balance',
            headerStyle: { },

            formatter: (cell, row) => row.name === 'no data' ? '' : formatCurrency(row.recurring_balance)
        },
        {
            dataField: "delinquent_amount",
            text: 'Missed',
            headerStyle: {  textAlign: 'center' },
            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-center'>{formatCurrency(row.delinquent_amount)}</div>
        },
        {
            dataField: "next_payment_success_rate",
            text: '%',
            headerStyle: {  textAlign: 'center' },
            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-center'>{row.next_payment_success_rate + '%'}</div>
        },
        
       
        {
            dataField: "last_payment_status_message",
            text: 'Message',
            headerStyle: { textAlign: 'right' },

            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-right'>{row.last_payment_status_message}</div>
        },
        {
            dataField: "delinquent_since",
            text: 'Delinquent',
            headerStyle: { width: 160,  textAlign: 'right' },

            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-right'>{moment.unix(row.delinquent_since).format('MM/DD/YYYY')}</div>
        },

    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Delinquent Plans'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={6}
        />


        <Container fluid>

           
            <Row>

                <div className="col-auto" style={{width: 250}}>
                    <Card className="custom-shadow-1">
                        <CardHeader>
                            <CardTitle className="mb-0 text-uppercase">Delinquent Filter</CardTitle>
                        </CardHeader>
                    
                        <CardBody>
                    
                            <ReactSelect
                                title={"Delinquent Range"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setRange(obj.value)}
                                options={[
                                    { value: 'all', label: '-' },
                                    { value: '0-30', label: '0 - 30 Days' },
                                    { value: '31-60', label: '31 - 60 Days' },
                                    { value: '61-90', label: '61 - 90 Days' },
                                    { value: '91-180', label: '91 - 180 Days' },
                                    { value: '180+', label: '180 Days +' },
                                ]}
                                value={range}
                            />   

                            <FormGroup>
                                <label className='form-control-label'>Failed COUNT Greater Than</label>
                                <Input 
                                    type="number"
                                    value={failedCountPlaceholder}
                                    onChange={e => setFailedCountPlaceholder(e.target.value)}
                                    onBlur={e => setFailedCount(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className='form-control-label'>Failed AMOUNT Greater Than</label>
                                <Input 
                                    type="number"
                                    value={failedAmountPlaceholder}
                                    onChange={e => setFailedAmountPlaceholder(e.target.value)}
                                    onBlur={e => setFailedAmount(e.target.value)}
                                />
                            </FormGroup>
                    
                            <ReactSelect
                                title={"Run Time"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setRunTime(obj.value)}
                                options={[
                                    { value: 'all', label: '-' },
                                    { value: 'morning', label: 'Morning' },
                                    { value: 'night', label: 'Night' },
                                ]}
                                value={runTime}
                            />    

                            <hr />
                            <ReactSelect
                                title={"Sort By"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setSort(obj.value)}
                                options={[
                                    { value: 'oldest', label: 'Oldest Delinquent' },
                                    { value: 'newest', label: 'Newest Delinquent' },
                                ]}
                                value={sort}
                            />    

                        </CardBody>
                    </Card>
                
                </div>

                <Col>

                    {loading ? <div className="py-6"><Circle /></div> : (
                        <div>

                            <Row>
                                <Col md={3}>
                                    <Card className='text-center custom-shadow-1'>
                                        <CardHeader>
                                            <CardTitle className='mb-0'>Total Plans</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <h1 className='mb-0 py-4'>{tableData.overview.total}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={3}>
                                    <Card className='text-center custom-shadow-1'>
                                        <CardHeader>
                                            <CardTitle className='mb-0'>Batch Total</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <h1 className='mb-0 py-4'>{formatCurrency(tableData.overview.total_amount)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card className='text-center custom-shadow-1'>
                                        <CardHeader>
                                            <CardTitle className='mb-0'>Balance Total</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <h1 className='mb-0 py-4'>{formatCurrency(tableData.overview.recurring_balance)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card className='text-center custom-shadow-1'>
                                        <CardHeader>
                                            <CardTitle className='mb-0'>Missed Total</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <h1 className='mb-0 py-4'>{formatCurrency(tableData.overview.delinquent_amount)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Card className="card-color card-primary table-fixed table-no-stripes custom-shadow-1">

                                <CardFooter>
                                    <CardTitle className="mb-0 text-uppercase">Delinquent Plans</CardTitle>
                                </CardFooter>

                                <Table
                                    hideSearch={true}
                                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                                    placeholder="Search Matters..."
                                    query={query}
                                    columns={columns}
                                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                                    totalDocuments={tableData.total_documents}

                                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                                />
                            </Card>
                        </div>
                    )}

                </Col>
            </Row>
    
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(DelinquentMatters);
