import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, Col, Container, Row, Badge } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import DatePicker from 'react-datepicker';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";
import UserIcons from 'components/markup/icons/UserIcons'

import { toast } from 'react-toastify';

const SupervisorSupportTickets = ({DOMAINS, selected_division}) => {


    const [ user, setUser ] = useState(null);
    const [ hasDueDate, setHasDueDate ] = useState(true);
    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));


    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    const onSetStart = useCallback((s) => {
        const _start = parseInt(moment(s).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newEnd = moment(s).endOf('month').toDate();
            setEnd(newEnd)
        }
        setStart(s)
    }, [end])
    
    const onSetEnd = useCallback((e) => {
        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(e).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newStart = moment(e).startOf('month').toDate();
            setStart(newStart)
        }
        setEnd(e)
    }, [start])

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.sort = { created_at: -1 }

        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_start > _end) return toast.info(`Search due date cannot be greater than end date.`)
        if(_end > (_start + ( 86400 * 32 ))) return toast.info(`Search range must span no more than 1 month`)

        if(user) params.filter.assigned_to = { $in: [user] }
        // if(resolved !== 'all') params.filter.resolved_at = resolved ? { $gt: 0 } : 0;

        if(hasDueDate) {
            params.filter.due_date = { $gte: _start, $lte: _end }
        } else {
            params.filter.due_date = 0
        }

        params.filter.finished_at = 0

        const query = await api._supervisor.outstandingItems(selected_division._id, { ...params, isCSV })

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents })
        setErr(query.message)
    }), [selected_division._id, sizePerPage, start, end, hasDueDate, user])

    useEffect(() => {
        query()
        // eslint-disable-next-line
    }, [start, end, hasDueDate, user])

    const columns = [
        {
            dataField: "name",
            text: 'Item',

            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div style={{width: 300}}>
                        <div className='text-uppercase'><b><A href={`${DOMAINS.APP}/matters/${row.matter}`}><ObjectFinder collection="matters" _id={row.matter} /></A></b></div>
                        {row.name}
                        {row.description ? <div className='text-muted'>{row.description}</div> : ''}
                    </div>
                )
            },
        },
        {
            dataField: "assigned_to",
            text: 'Assigned To',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : <UserIcons users={row.assigned_to} />
            )
        },
        {
            dataField: "priority",
            text: 'Priority',
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''
                if(row.priority === 1) return <Badge color="danger">Highest</Badge>
                if(row.priority === 2) return <Badge color="warning">High</Badge>
                if(row.priority === 3) return <Badge color="info">Default</Badge>
                if(row.priority === 4) return <Badge color="purple">Low</Badge>
                if(row.priority === 5) return <Badge color="success">Lowest</Badge>
            }
        },
        {
            dataField: "due_date",
            text: 'Due Date',
            headerStyle: { width: 150 },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.due_date ? (
                    <div>
                        <div>{moment.unix(row.due_date).format('MM/DD/YYYY')}</div>
                        <div>{moment.unix(row.due_date).format('h:mm A')}</div>
                    </div>
                ) : '-'
            )
        },
        {
            dataField: "contact",
            text: 'Contact',

            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return !row.contact ? '-' : (
                    <A href={`${DOMAINS.APP}/contacts/${row.contact}`} className="text-dark font-weight-bold">
                        <ObjectFinder collection="contacts" _id={row.contact} />
                    </A>
                )
            },
        },
        {
            dataField: "created_by",
            text: 'Created By',
            headerStyle: { width: 150 },
            formatter: (cell, row) => (
                row.created_by ? <ObjectFinder collection="users" _id={row.created_by} /> : ''
            )
        },
      
      
        {
            dataField: "workflow_step",
            text: 'Workflow Step',
            headerStyle: { width: 150 },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.workflow_step ? <ObjectFinder collection="workflow_steps" _id={row.workflow_step} /> : '-'
            )
        },
       
        // {
        //     dataField: "call_legs",
        //     text: 'Assigned Users',
        //     formatter: (cell, row) => {
        //         if(row.name === 'no data') return '';

        //         return row.assigned_users ? row.assigned_users.map((user, i) => (
        //             <div key={i}><ObjectFinder collection="users" _id={user} /></div>
        //         )) : ''
        //     }
        // },
        
        // {
        //     dataField: "updated_at",
        //     text: 'Last Updated',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : row.resolved_at ? (
        //             <Badge style={{width: 110}} color="success">Resolved</Badge>
        //         ) : (
        //             <Badge style={{width: 110}} color="danger">Unresolved</Badge>
        //         )
        //     )
        // },
        // {
        //     dataField: "types",
        //     text: 'Ticket Type(s)',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : row.types.map((t, i) => (
        //             <span key={i}>{i !== 0 ? ', ' : ''}{t}</span>
        //         ))
        //     )
        // },
        // {
        //     dataField: "snippet",
        //     text: 'Snippet',
        //     headerStyle: { width: 300 },
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : row.snippet
        //     )
        // },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Outstanding Items'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description="All Outstanding Items"
            leftColSize={4}
            actionComponent={(
                <Row>
                    <Col md={3}>
                        <SearchCollections
                            collection={'users'}
                            title={<span>User</span>}
                            placeholder="..."
                            value={user}
                            onChange={(obj) => setUser(obj.value)}
                            isClearable={true}
                            filter={{
                                divisions: { $in: [selected_division._id] },
                            }}
                        /> 
                    </Col>
                    <Col md={3}>
                    
                        <ReactSelect
                            title={"Due Date"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setHasDueDate(obj.value)}
                            options={[
                                { value: true, label: 'Has Due Date' },
                                { value: false, label: 'Does Not Have Due Date' },
                            ]}
                            value={hasDueDate}
                        />    
                    </Col>
                
                    <Col md={3} style={{opacity: !hasDueDate ? .5 : 1}}>
                        <p className="text-sm mb-0">Select Due Date Start</p>
                        <DatePicker 
                            disabled={!hasDueDate}
                            selected={start} 
                            onChange={(date) => onSetStart(date)} 
                        />
                    </Col>
                    <Col md={3} style={{opacity: !hasDueDate ? .5 : 1}}>
                        <p className="text-sm mb-0">Select Due Date End</p>
                        <DatePicker 
                            disabled={!hasDueDate}
                            selected={end} 
                            onChange={(date) => onSetEnd(date)} 
                        />
                    </Col>
                
                </Row>
            )}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(SupervisorSupportTickets);
