import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import ModalToggler from 'components/functional/modals/Toggler'
import ConfirmationModal from 'components/functional/modals/Confirmation';

import { toast } from 'react-toastify'

import Edit from './Edit'

const MattersAll = ({selected_division, DOMAINS}) => {

    const [ courtCode, setCourtCode ] = useState('miebke');

    const [ trustees, setTrustees ] = useState(null)
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async () => {
        setTrustees('')
        const result = await api.modules.bk.trustees.find(selected_division._id, courtCode)
        if(result.data) return setTrustees(result.data)

        setErr(result.message)
    }, [courtCode, selected_division._id])
    
    const onDelete = useCallback(async (_id) => {
        const result = await api.modules.bk.trustees.delete(_id)
        if(!result.success) return toast.error(`Could not delete trustee, please try again`)

        fetchData()
    }, [fetchData])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'BK Trustees'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={5}
        />

        <Card className="mt--4">
            <CardBody>

                <ReactSelect
                    title={"Select District"}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => setCourtCode(obj.value)}
                    options={[
                        { value: 'miebke', label: 'Eastern District Of Michigan' },
                        { value: 'miwbke', label: 'Western District Of Michigan' },
                        { value: 'ohnbke', label: 'Northern District Of Ohio' },
                        { value: 'ohsbke', label: 'Southern District Of Ohio' },
                    ]}
                    value={courtCode}
                />    

            </CardBody>
        </Card>

        {!trustees ? <Circle /> : (
            <Container fluid>
                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">
                            <Row>
                                <Col md={6} className="align-self-center">
                                    District Trustees
                                </Col>
                                <Col md={6} className="align-self-center text-right">
                                    <ModalToggler component={Edit} courtCode={courtCode} fetchData={fetchData}>
                                        <button className="btn btn-sm btn-success">
                                            <i className="fas fa-plus mr-3" /> Add 
                                        </button>
                                    </ModalToggler>
                                    
                                </Col>
                            </Row>
                        </CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Trustee</th>
                                    <th>Phone</th>
                                    <th>Court Division</th>
                                    <th>Payment Address</th>
                                    <th>Hearing Location</th>
                                    <th>Court</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {trustees.length ? trustees.map(t => (
                                    <tr key={t._id}>
                                        <td>{t.name}</td>
                                        <td>{t.phone}</td>
                                        <td>{t.court_division ? <i className="fas fa-check text-success" /> : ''}</td>
                                        <td>{t.payment_address ? <i className="fas fa-check text-success" /> : ''}</td>
                                        <td>{t.hearing_address ? <i className="fas fa-check text-success" /> : ''}</td>
                                        <td>
                                            {t.court_name && t.court_address_line_1 && t.court_city && t.court_state && t.court_postal_code ? <i className="fas fa-check text-success" /> : ''}
                                        </td>
                                        <td className="text-right">
                                            <ModalToggler 
                                                component={ConfirmationModal} 
                                                courtCode={courtCode} 
                                                fetchData={fetchData} 
                                                title={`Delete Trustee`}
                                                body={<span>Are you sure you wish to delete the trustee <b className="text-underline">{t.name}</b></span>}
                                                onConfirmation={() => onDelete(t._id)}
                                            >
                                                <button className="btn btn-sm btn-outline-danger mr-3">
                                                    <i className="fas fa-trash" />
                                                </button>
                                            </ModalToggler>
                                            <ModalToggler 
                                                component={Edit} 
                                                courtCode={courtCode} 
                                                fetchData={fetchData} 
                                                _id={t._id}
                                            >
                                                <button className="btn btn-sm btn-info">
                                                    <i className="fas fa-edit mr-2" /> Edit
                                                </button>
                                            </ModalToggler>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No Trustees Found For District</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </Container>
        )}

       
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
