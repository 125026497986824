import { useCallback } from "react";
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardHeader, CardTitle, Col, Modal, Row } from "reactstrap";

import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';
import formatText from 'utils/formatText';
import ModalToggler from 'components/functional/modals/Toggler';

const ModalDetails = ({showModal, toggleModal, callLeg}) => {

    const empty = !callLeg.jitter_inbound && !callLeg.latency_inbound && !callLeg.packet_loss_percentage_inbound && !callLeg.jitter_outbound && !callLeg.jitter_inbound && !callLeg.packet_loss_percentage_outbound;
    let failed = callLeg.compilation_status === 'failed';

    const now = Math.floor(new Date() / 1000)

    if(empty && callLeg.end < (now - 3600)) failed = true;

    const notAvailable = empty || failed;

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
    
            <div className="modal-header">
                <h5 className="modal-title">
                    Call Quality:{' '}
                    {callLeg.user ? (
                        <ObjectFinder collection="users" _id={callLeg.user} />
                    ) : (
                        <ObjectFinder collection="contacts" _id={callLeg.contact} />
                    )}    
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
    
            <div className="modal-body border-bottom bg-secondary">
    
                <Row>
                    <Col md={6}>
                        <h3>Inbound Metrics</h3>
    
                        <p className={`text-sm mb-0 ${notAvailable ? '' : callLeg.jitter_inbound > 30 ? 'text-warning' : 'text-success'}`}> 
                            <b style={{width: 110, display: 'inline-block'}}>Jitter: </b> {callLeg.jitter_inbound} MS
                        </p>
                        <p className={`text-sm mb-0 ${notAvailable ? '' : callLeg.latency_inbound > 150 ? 'text-warning' : 'text-success'}`}>  
                            <b style={{width: 110, display: 'inline-block'}}>Latency: </b> {callLeg.latency_inbound} MS
                        </p>
                        <p className={`text-sm mb-0 ${notAvailable ? '' : callLeg.packet_loss_percentage_inbound > 1 ? 'text-warning' : 'text-success'}`}> 
                            <b style={{width: 110, display: 'inline-block'}}>Packet Loss: </b> {callLeg.packet_loss_percentage_inbound}%
                        </p>
    
                    </Col>
                    <Col md={6}>
                        <h3>Outbound Metrics</h3>
    
                        <p className={`text-sm mb-0 ${notAvailable ? '' : callLeg.jitter_outbound > 30 ? 'text-warning' : 'text-success'}`}> 
                            <b style={{width: 110, display: 'inline-block'}}>Jitter: </b> {callLeg.jitter_outbound} MS
                        </p>
                        <p className={`text-sm mb-0 ${notAvailable ? '' : callLeg.latency_outbound > 150 ? 'text-warning' : 'text-success'}`}> 
                            <b style={{width: 110, display: 'inline-block'}}>Latency: </b> {callLeg.latency_outbound} MS
                        </p>
                        <p className={`text-sm mb-0 ${notAvailable ? '' : callLeg.packet_loss_percentage_outbound > 1 ? 'text-warning' : 'text-success'}`}> 
                            <b style={{width: 110, display: 'inline-block'}}>Packet Loss </b> {callLeg.packet_loss_percentage_outbound}%
                        </p>
    
                    </Col>
                </Row>
    
                <hr  className="mb-2"/>
               
                {empty || failed ? (
                    <div className={failed ? 'text-warning' : 'text-info'}>
                        <small>**{failed ?  
                            <span>
                                Metrics for this call leg are not available however they may be available via your twilio dashboard{' '}
                                <A  className="text-underline text-warning" href={`https://console.twilio.com/us1/monitor/logs/calls?frameUrl=/console/voice/calls/logs/${callLeg.call_sid}`}>HERE</A>
                                </span> 
                            : 'Metrics for this call leg are not yet available, generally call leg details are compiled within 30 minutes of a call finishing'}.
                            <hr  className="mt-2 mb-2"/>  
                        </small>
                    </div>
                ) : ''}
                <div>
                    <small>**For a call to be considered good quality Jitter should be under 30 miliseconds, latency should be under 150 miliseconds, and packet loss percentage should be under 1%.</small>
                </div>
                
            </div>
    
            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>
    
        </Modal>
    
    )
}



const MattersAll = ({callLog}) => {

     const getParticipant = useCallback((p) => {
        if(!p) return 'SYSTEM'
        try {
            if(p.includes('user')) return <ObjectFinder collection="users" _id={`${p.split(':')[1]}`} />
            if(p.includes('contact')) return <ObjectFinder collection="contacts" _id={`${p.split(':')[1]}`} />
            return '-'
        } catch(e) {
            return '-'
        }
    }, [])

    return (

        <Card className="card-color card-primary table-fixed custom-shadow-1">

            <CardHeader className="bg-gradient-success text-white">
                <CardTitle className="mb-0">Call Properties</CardTitle>
            </CardHeader>

            <div className="table-responsive ">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th className="text-right">Value</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Contact</td>
                            <td className="text-right">{callLog.contact ? <ObjectFinder collection="contacts" _id={callLog.contact} /> : ''}</td>
                        </tr>
                        <tr>
                            <td>Direction</td>
                            <td className="text-right">{callLog.direction}</td>
                        </tr>
                        <tr>
                            <td>Hour Of Day (24 Hour)</td>
                            <td className="text-right">{callLog.hour_of_day}</td>
                        </tr>
                        <tr>
                            <td>Day Of Week</td>
                            <td className="text-right">
                                {
                                    callLog.day_of_week === 0 ? 'Sunday' :
                                    callLog.day_of_week === 1 ? 'Monday' :
                                    callLog.day_of_week === 2 ? 'Tuesday' :
                                    callLog.day_of_week === 3 ? 'Wednesday' :
                                    callLog.day_of_week === 4 ? 'Thursday' :
                                    callLog.day_of_week === 5 ? 'Friday' :
                                    callLog.day_of_week === 6 ? 'Saturday' :
                                    '-'
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Start</td>
                            <td className="text-right">{callLog.start ? moment.unix(callLog.start).format('MM/DD/YYYY hh:mm A') : ''}</td>
                        </tr>
                        <tr>
                            <td>End</td>
                            <td className="text-right">{callLog.end ? moment.unix(callLog.end).format('MM/DD/YYYY hh:mm A') : ''}</td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td className="text-right">{callLog.duration ? formatText(callLog.duration).secondsToTime() : ''}</td>
                        </tr>
                        <tr>
                            <td>Division</td>
                            <td className="text-right">{callLog.division ? <ObjectFinder collection="divisions" _id={callLog.division} /> : ''}</td>
                        </tr>
                        <tr>
                            <td>Division Department</td>
                            <td className="text-right">{callLog.division_department ? <ObjectFinder collection="division_departments" _id={callLog.division_department} /> : ''}</td>
                        </tr>

                        <tr>
                            <td>Workflow</td>
                            <td className="text-right">{callLog.workflow ? <ObjectFinder collection="workflows" _id={callLog.workflow} /> : ''}</td>
                        </tr>
                        <tr>
                            <td>Workflow Disposition</td>
                            <td className="text-right">{callLog.workflow_disposition ? <ObjectFinder collection="workflow_dispositions" _id={callLog.workflow_disposition} /> : ''}</td>
                        </tr>
                        <tr>
                            <td>Workflow Step</td>
                            <td className="text-right">{callLog.workflow_step ? <ObjectFinder collection="workflow_steps" _id={callLog.workflow_step} /> : ''}</td>
                        </tr>
                        <tr>
                            <td>Workflow Step Category</td>
                            <td className="text-right">{callLog.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={callLog.workflow_step_category} /> : ''}</td>
                        </tr>

                        <tr>
                            <td>From Number</td>
                            <td className="text-right">{callLog.from ? formatText(callLog.from).phone() : ''}</td>
                        </tr>
                        <tr>
                            <td>To Number</td>
                            <td className="text-right">{callLog.to ? formatText(callLog.to).phone() : ''}</td>
                        </tr>


                        <tr>
                            <td>After Hours</td>
                            <td className="text-right">{callLog.after_hours ? 'yes' : 'no'}</td>
                        </tr>
                        <tr>
                            <td>Unknown Caller</td>
                            <td className="text-right">{callLog.unknown ? "Yes" : 'No'}</td>
                        </tr>
                        

                        <tr>
                            <td>Call Legs</td>
                            <td className="text-right">{callLog.call_legs.length}</td>
                        </tr>
                        <tr>
                            <td>Audit Date</td>
                            <td className="text-right">{callLog.audit_date ? moment.unix(callLog.audit_date).format('MM/DD/YYYY hh:mm A') : ''}</td>
                        </tr>
                        <tr>
                            <td>Audited By</td>
                            <td className="text-right">{callLog.audited_by ? <ObjectFinder collection="users" _id={callLog.audited_by} /> : ''}</td>

                        </tr>
                        <tr>
                            <td>Call Queue</td>
                            <td className="text-right">{callLog.call_queue ? <ObjectFinder collection="call_queues" _id={callLog.call_queue} /> : ''}</td>
                        </tr>
                        <tr>
                            <td>Conference ID</td>
                            <td className="text-right">{callLog.conference_sid ? callLog.conference_sid : ''}</td>
                        </tr>
                        <tr>
                            <td>Call ID</td>
                            <td className="text-right">{callLog._id ? callLog._id : ''}</td>
                        </tr>
                        
                        

                        
                        <tr>
                            <td>Status</td>
                            <td className="text-right">{callLog.status ? callLog.status : ''}</td>
                        </tr>

                        <tr>
                            <td>Time Till Abandoned</td>
                            <td className="text-right">
                                <span className={callLog.time_till_abandoned ? 'text-danger' : ''}>
                                    {callLog.time_till_abandoned ? formatText(callLog.time_till_abandoned).secondsToTime() : '-'}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td>Time Till Connected</td>
                            <td className="text-right">
                                <span className={callLog.time_till_connected ? 'text-success' : ''}>
                                    {callLog.time_till_connected ? formatText(callLog.time_till_connected).secondsToTime() : '-'}
                                </span>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>Recorded</td>
                            <td className="text-right">{callLog.recording_url ? "Yes" : 'No'}</td>
                        </tr>
                        
                        <tr>
                            <td>Recording ID</td>
                            <td className="text-right">{callLog.recording_sid ? callLog.recording_sid : ''}</td>
                        </tr>
                        <tr>
                            <td>Recording Duration</td>
                            <td className="text-right">{callLog.recording_duration ? formatText(callLog.recording_duration).secondsToTime() : ''}</td>
                        </tr>

                        <tr>
                            <td>Recording URL</td>
                            <td className="text-right">
                                {callLog.recording_url ? (
                                    <span>
                                        <a className="btn btn-success btn-sm" download href={callLog.recording_url + '.mp3?Download=true' }>Download <i className="fas fa-download" /></a>
                                        <A className="btn btn-info btn-sm" href={callLog.recording_url}>OPEN <i className="fas fa-link" /></A>
                                    </span>
                                ) : ''}
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>


            <CardHeader className="bg-gradient-success text-white">
                <CardTitle className="mb-0">Call Events</CardTitle>
            </CardHeader>

            <div className="table-responsive ">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Participant</th>
                            <th className="text-right">Event</th>
                        </tr>
                    </thead>

                    <tbody>
                        {callLog.events.map(e => (
                            <tr key={e._id}>
                                <td>{moment.unix(e.timestamp).format('h:mm:ss A')}</td>
                                <td>{getParticipant(e.participant)}</td>
                                <td className="text-right">{e.event}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        
            <CardHeader className="bg-gradient-success text-white">
                <CardTitle className="mb-0">Call Legs</CardTitle>
            </CardHeader>

            <div className="table-responsive ">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Participant</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Duration</th>
                            <th>Status</th>
                            <th className="text-right">Call ID</th>
                        </tr>
                    </thead>

                    <tbody>
                    {/* // Jitter is measured in milliseconds (ms), and ideally, an acceptable jitter level should stay below 30 ms. Anything higher than 30, and you may start to see issues with audio or video quality. As a rule, packet loss should stay under 1%, and network latency shouldn’t exceed 150 ms. */}
                        {callLog.call_legs.map(e => (
                            <tr key={e._id}>
                                <td>
                                    <span className={`cursor-pointer ${e.has_issue ? 'text-warning' : ''}`}>
                                        <ModalToggler component={ModalDetails} callLeg={e}>
                                            {e.user ? (
                                                <ObjectFinder collection="users" _id={e.user} />
                                            ) : e.contact ? (
                                                <ObjectFinder collection="contacts" _id={e.contact} />
                                            ) : formatText(e.number).phone()}    
                                            <i className={`fas fa-asterisk pl-2 ${e.has_issue ? 'text-warning' : 'text-info'}`} />
                                        </ModalToggler>

                                    </span>
                                </td>
                                <td>{moment.unix(e.start).format('h:mm:ss A')}</td>
                                <td>{moment.unix(e.end).format('h:mm:ss A')}</td>
                                <td>{formatText(e.duration).secondsToTime()}</td>
                                <td>{e.status}</td>
                                <td className="text-right">
                                    <A 
                                        className="btn btn-info btn-sm"
                                        href={`https://console.twilio.com/us1/monitor/logs/calls?frameUrl=/console/voice/calls/logs/${e.call_sid}`}
                                    >
                                        OPEN <i className="fas fa-link" />
                                    </A>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {callLog.direction === 'inbound' ? (
                <>
                    <CardHeader className="bg-gradient-success text-white">
                        <CardTitle className="mb-0">Routing Tree</CardTitle>
                    </CardHeader>

                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th className="text-right" style={{width: 100   }}>Time</th>
                                </tr>
                            </thead>
            
                            <tbody>
                                {callLog.routing_events.map(e => {
                                    let matches = []
                                    let objects = []
                                    let text = e.event
                                    matches = matches.concat(text.match(/"call_flow(.*?)"/g,))
                                    matches = matches.concat(text.match(/"custom_field(.*?)"/g,))
                                    matches = matches.concat(text.match(/"workflow_disposition(.*?)"/g,))
                                    matches = matches.concat(text.match(/"workflow_step_category(.*?)"/g,))
                                    matches = matches.concat(text.match(/"user(.*?)"/g,))
                                    matches = matches.concat(text.match(/"call_queue(.*?)"/g,))

                                    matches = matches.filter(m => m)

                                    let markup = [];

                                    matches.forEach((m, i) => {
                                        let collection = m.includes('call_flow') ? 'call_flows' : 
                                            m.includes('custom_field') ? 'custom_fields' :
                                            m.includes('workflow_disposition') ? 'workflow_dispositions' :
                                            m.includes('workflow_step_category') ? 'workflow_step_categories' :
                                            m.includes('user') ? 'users' :
                                            m.includes('call_queue') ? 'call_queues' :
                                            '';

                                        let id = m.split('.')[1].split('"')[0]

                                        text = text.replace(m, "match");

                                        objects.push({ id, collection })

                                    })

                                    if(matches.length) {
                                        const split = text.split('match')

                                        split.forEach((s, i) => {
                                            markup.push(s)
                                            if(objects[i]) {
                                                markup.push((
                                                    <b className="text-underline">
                                                        <ObjectFinder collection={objects[i].collection} _id={objects[i].id} />
                                                    </b>
                                                ))
                                            }
                                        })
                                    } else {
                                        // markup.push(<b className="text-danger">{e.event}</b>)
                                        markup.push(<b className={e.event.includes('Asking user for input') ? '' : 'text-danger'}>{e.event}</b>)
                                    }

                                    return (
                                        <tr key={e._id}>
                                            <td> {markup}</td>
                                            <td className="text-right">
                                                {moment.unix(e.timestamp).format('h:mm:ss A')}
                                                {/* <div>{moment.unix(e.timestamp).format('ss')} seconds</div> */}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
        ) : ''}

        </Card>

    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
