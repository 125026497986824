import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { Card, CardBody, Col, Container, Row, CardFooter, FormGroup, Input } from "reactstrap";


import ReactSelect from 'components/functional/inputs/ReactSelect';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from "components/system/Search/Collections";

import store from 'store'
import api from 'api';

import DatePicker from 'react-datepicker';

import { toast } from 'react-toastify';

import { CSVLink } from "react-csv";

const RenderAttorney = ({attorney}) => {

    const [show, setShow] = useState(true)

    useEffect(() => {
        setShow(false)

        setTimeout(() => {
            setShow(true)
        }, 250)
    }, [attorney])

    return show ? <ObjectFinder collection="users" _id={attorney} /> : <></>
}

const MattersAll = ({selected_division, DOMAINS}) => {
    const [ chapter, setChapter ] = useState('');
    const [ courtCode, setCourtCode ] = useState('');
    const [ state, setState ] = useState('');

    const [ csvData, setCSVData ] = useState(null);
    const [ user, setUser ] = useState('');
    const [ day, setDay ] = useState(moment().startOf('week').toDate())
    const [ end, setEnd ] = useState(moment().endOf('week').toDate())
    const [ searchString, setSearchString ] = useState('')
    const [ trusteeSearchString, setTrusteeSearchString ] = useState('')

    const [ allData, setAllData ] = useState(null)
    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)

    let allEntries = data ? data : [];

    if(user) allEntries = allEntries.filter(i => i.attorney === user)
    if(chapter) allEntries = allEntries.filter(i => i.chapter === chapter)
    if(courtCode) allEntries = allEntries.filter(i => i.court_code === courtCode)
    if(searchString) allEntries = allEntries.filter(i => i.hearings.some(h => h.name && h.name.toLowerCase().includes(searchString.toLowerCase())))
    if(trusteeSearchString) allEntries = allEntries.filter(i => i.trustee && i.trustee.toLowerCase().includes(trusteeSearchString.toLowerCase()))
    
    if(state) allEntries = allEntries.filter(i => 
        state === 'mi' ? i.court_code === 'miebke' || i.court_code === 'miwbke' :
        state === 'oh' ? i.court_code === 'ohnbke' || i.court_code === 'ohsbke' :
        true
    )


    console.log(allEntries)

    const fetchData = useCallback(async () => {
        const params = {
            start: parseInt(moment(day).startOf('day').format('X')),
            end: parseInt(moment(end).endOf('day').format('X')),
        }
        const query = await api.modules.bk.supervisor.hearings(selected_division._id, params)
        if(!query.success) {
            setErr('Please refresh your page.')
            return toast.error(`Could not load results, please try again`)
        }
        let compiled = [];

        query.data.forEach((d, i) => {
            d.day = moment.unix(d.date).format('MM/DD/YYYY')

            const index = compiled.findIndex(dd => dd.day === d.day && dd.attorney === d.attorney && dd.case_number === d.case_number)

            if(index !== -1) {
                compiled[index].hearings.push({ 
                    _id: d._id, 
                    date: d.date, 
                    name: d.name 
                })
            } else {
                compiled.push({
                    day: d.day,
                    matter: d.matter,
                    matter_name: d.matter_name,
                    bk_case: d.bk_case,
                    trustee: d.trustee,
                    attorney: d.attorney,
                    case_number: d.case_number,
                    court_code: d.court_code,
                    chapter: d.chapter,
                    hearings: [{ 
                        _id: d._id, 
                        date: d.date, 
                        name: d.name 
                    }]
                })
            }
        })

        setData(compiled)
        setAllData(query.data)
    }, [day, end, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

   
    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = `Bankruptcy Hearings (${allEntries.length})`

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={12}

        />

        <Card className="mt--4">
            <CardBody>

                <Row>
                
                    <Col md={3}>
                        <ReactSelect
                            title={"Select District"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setCourtCode(obj.value)}
                            options={[
                                { value: '', label: 'All Districts' },
                                { value: 'miebke', label: 'Eastern District Of Michigan' },
                                { value: 'miwbke', label: 'Western District Of Michigan' },
                                { value: 'ohnbke', label: 'Northern District Of Ohio' },
                                { value: 'ohsbke', label: 'Southern District Of Ohio' },
                            ]}
                            value={courtCode}
                        />    
                    </Col>
                    <Col md={3}>
                        <ReactSelect
                            title={"Select State"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setState(obj.value)}
                            options={[
                                { value: '', label: 'All' },
                                { value: 'mi', label: 'Michigan' },
                                { value: 'oh', label: 'Ohio' },
                            ]}
                            value={courtCode}
                        />    
                    </Col>

                    <Col md={3}>
                        <p className={`text-sm mb-0`}>Hearings From</p>
                        <DatePicker 
                            selected={day} 
                            onChange={(date) => setDay(date)} 
                        />
                    </Col>
                

                    <Col md={3}>
                        <p className={`text-sm mb-0`}>Hearings Until</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => setEnd(date)} 
                        />
                    </Col>

                    <Col md={6}>
                        <p className="text-sm mb-0">Search By User</p>
                        <SearchCollections
                            collection={'users'}
                            hideTitle={true}
                            placeholder="Search..."
                            value={user}
                            onChange={(obj) => setUser(obj.value)}
                            filter={{
                                division: selected_division._id,
                            }}
                        /> 
                    </Col>

                    <Col md={6}>
                        <ReactSelect
                            title={"Chapter"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setChapter(obj.value)}
                            options={[
                                { value: '', label: 'All', },
                                { value: 7, label: '7'  },
                                { value: 13, label: '13'  },
                            ]}
                            value={chapter}
                        />    
                    </Col>
                
                </Row>

                <FormGroup>
                    <label className="form-control-label">Search Hearing Type</label>
                    <Input 
                        type="text"
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <label className="form-control-label">Search Trustee</label>
                    <Input 
                        type="text"
                        value={trusteeSearchString}
                        onChange={e => setTrusteeSearchString(e.target.value)}
                    />
                </FormGroup>

            </CardBody>
        </Card>

        <Container fluid>


        
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Matter</th>
                            <th>Case Info</th>
                            <th>Trustee</th>
                            <th>Chapter</th>
                            <th>Attorney</th>
                            <th>Hearing Date && Time</th>
                            <th>Hearing Type</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {allEntries.length ? allEntries.map((d, i) => (
                            <tr key={d._id + i.toString()}>
                                <td><A href={`${DOMAINS.APP}/matters/${d.matter}?showModule=bk&moduleTab=CaseStage`}  >{d.matter_name}</A></td>
                                <td>
                                    <div>
                                        <div>{d.case_number}</div>
                                        <div>{d.court_code}</div>
                                    </div>
                                </td>
                                <td>{d.trustee}</td>
                                <td>{d.chapter ? d.chapter : ''}</td>
                                <td>{d.attorney ? <RenderAttorney attorney={d.attorney} /> : '-'}</td>
                                <td>
                                    {d.hearings.map((dd, ii) => (
                                        <div key={ii}>{moment.unix(dd.date).format('MM/DD/YYYY h:mm A')}</div>
                                    ))}
                                </td>
                                <td>
                                    {d.hearings.map((dd, ii) => (
                                        <div key={ii}>{dd.name}</div>
                                    ))}
                                </td>
                                <td className="text-right">
                                    <A className="btn btn-sm btn-info" href={`${DOMAINS.APP}/matters/${d.matter}?showModule=bk&moduleTab=CaseStage`}  >
                                        <i className="fas fa-link mr-2" /> Open 
                                    </A>
                                </td>

                            </tr>
                        )) : (
                            <tr>
                                <td>No Hearings Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                    </tbody>

                </table>

                <CardFooter className="text-right">
                    <CSVLink
                        data={csvData ? csvData : []} 
                        filename={"hearings.csv"}
                        asyncOnClick={true} 
                        onClick={(event, done) => {
                            if(csvData) return true;
                            event.preventDefault()

                            const formatted = []
                            const state = store.getState();
                            const users = state.objects.users

                            let all = JSON.parse(JSON.stringify(allData))

                            if(user) all = all.filter(i => i.attorney === user)
                            if(chapter) all = all.filter(i => i.chapter === chapter)
                            if(courtCode) all = all.filter(i => i.court_code === courtCode)

                            all.forEach(d => {
                                const attorney = users[d.attorney];

                                const row = {
                                    matter: d.matter_name,
                                    case_number: d.case_number,
                                    court_code: d.court_code,
                                    chapter: d.chapter,
                                    date:  moment.unix(d.date).tz('America/New_York').format('MM/DD/YYYY h:mm A'),
                                    trustee: d.trustee,
                                    judge: d.judge,
                                    attorney: attorney ? attorney.display_name : '',
                                 
                                }


                                formatted.push(row)
                            })

                            setCSVData(formatted)

                            setTimeout(() => {
                                event.target.click()

                                setTimeout(() => {
                                    setCSVData(null)
                                }, 500)
                            }, 250)

                            return false;

                        }}
                    >
                        <button className="btn btn-success btn-sm mt-2">Export To Csv</button>
                    </CSVLink>

                </CardFooter>
            </Card>


        </Container>
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
