/*

Example Usage

 <PieArea 
    type="pie"
    title="New Leads"
    labels={['Red', 'Blue', 'Yellow', 'Green', 'Indigo', 'Purple', 'Orange', 'purple']}
    dataset={[12, 19, 3, 5, 2, 3, 5, 3]} ||
    dataset={[
        { data: 12, label: 'succeeded', color: 'red}
    ]}
/>

*/


import { Pie, PolarArea } from "react-chartjs-2";
import { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import settings from '../settings'

import formatText from 'utils/formatText'


const ChartBar = ({title, type, labelType, labels, colors, dataset, small, classNames}) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        let backgroundColor = []
        let borderColor = []

        if(colors) {
            colors.forEach(key => {
                backgroundColor.push(settings.colors[key] + '0.7)')
                borderColor.push(settings.colors[key] + '1)')
            })
        } else {
            Object.keys(settings.colors).forEach(key => {
                backgroundColor.push(settings.colors[key] + '0.7)')
                borderColor.push(settings.colors[key] + '1)')
            })
        }


        setData({
            labels: labels,
            datasets: [
                {
                  label: dataset.label ? dataset.label : 'Count',
                  data: dataset.data ? dataset.data : dataset,
                  backgroundColor: dataset.color ? dataset.color : backgroundColor,
                  borderColor: dataset.color ? dataset.color : borderColor,
                  borderWidth: 1,
                },
              ],
          })
    }, [type, labels, dataset, colors])

    if(!data) return null

    const Component = type === 'area' ? PolarArea : Pie

    return (
        <div className={classNames ? classNames : "border z-depth-1 mb-4"}>
            
            {title ? (
                <div className="p-4 border-bottom">
                    <h2 className=" mb-0">{title}</h2>
                </div>
            ) : ''}

            <div className="p-4">

                <div className="chart" style={{height: small ? 150 : 'inherit'}}>
                    <Component
                        data={data}
                        height={small ? 200 : 250}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    labels: {
                                      generateLabels: function(chart) {
                                        // Get the default label list
                                        const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                                        const labelsOriginal = original.call(this, chart);
                                        let total = 0
                            
                                        // Build an array of colors used in the datasets of the chart
                                        var datasetColors = chart.data.datasets.map(function(e) {
                                          return e.backgroundColor;
                                        });
                                        datasetColors = datasetColors.flat();
                                        // Modify the color and hide state of each label
                                        labelsOriginal.forEach((label, i) => {
                                            total += chart.data.datasets[0].data[i]
                                        });

                                        labelsOriginal.forEach((label, i) => {
                                            const percent = ((chart.data.datasets[0].data[i] / total) * 100).toFixed(2)
                                            label.text += percent !== 'NaN' ? ` ${percent}%` : ' 0%'
                                            label.fillStyle = datasetColors[label.index];
                                        });

                                        return labelsOriginal;
                                      }
                                    },
                                },
                                tooltip: {
                                  callbacks: {
                                    label: function (context) {

                                        if(labelType === 'time') return formatText(context.parsed).secondsToTime(null, true)
                                        if(labelType !== 'currency') return context.parsed

                                        let label = context.label || '';
                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed !== null) {
                                            label += new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            }).format(context.parsed);
                                        }
                                        return label;
                                    },
                                  },
                                },
                              },
                        }}

                        className="chart-canvas"
                    />
                </div>
            </div>
        </div>

    )
}

export default ChartBar;
