import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Container, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import SearchCollections from "components/system/Search/Collections";

import api from 'api';
import moment from 'moment';

import Billing from './Billing';
import CallMetrics from './CallMetrics';
import Categories from './Categories';
import CompanyHealth from './CompanyHealth';
import Dispositions from './Dispositions';
import ESignatures from './ESignatures';
import MatterDocuments from './MatterDocuments';
import ProgressBars from './ProgressBars';
import SupportTickets from './SupportTickets';
import TodaysLeads from './TodaysLeads';
import CustomHeader from './CustomHeader';

import DatePicker from 'react-datepicker'

const SupervisorTodaysActivity = ({selected_division}) => {

    const [data, setData] = useState(null)
    const [day, setDay] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [tag, setTag] = useState('')

    const fetchData = useCallback(async () => {

        const startTime = parseInt(moment(day).startOf('day').format('X'))
        const endTime = parseInt(moment(end).endOf('day').format('X'))

        if(startTime > endTime) return toast.info(`Start time cannot be less then end time`)

        if((startTime + ( 86400 * 35 )) < endTime) return  toast.info(`You must select a range under 31 days.`)
        const filter = { 
            start : startTime,
            end   : endTime
        }

        if(tag) filter.tag = tag

        setData(null);
        const result = await api._supervisor.todaysActivity(selected_division._id, filter )
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)

        setData(result.data)
    }, [day, end, tag, selected_division._id])

    useEffect(() => {
        fetchData();

        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    if(!data) return <Circle className="py-6" />

    return (

        <>

            <Helmet>
                <title>{`Todays Activity`}</title>
                <meta name="description" content="Todays Activity" />
            </Helmet>

            <HeaderNavigation 
                title="Daily Activity"
                description="Today At A glance"
                leftColSize={4}
                actionComponent={(
                    <Row>
                        <Col md={3}></Col>
                    
                        <Col md={3}>
                            <p className="text-sm mb-0">Filter Results</p>
                            <SearchCollections
                                collection={'tags'}
                                hideTitle={true}
                                placeholder="Search..."
                                value={tag}
                                onChange={(obj) => setTag(obj.value)}
                                filter={{
                                    division: selected_division._id,
                                }}
                            /> 
                        </Col>

                        <Col md={3}>
                            <p className="text-sm mb-0">Select Start</p>
                            <DatePicker 
                                selected={day} 
                                onChange={(date) => setDay(date)} 
                            />
                        </Col>
                    

                        <Col md={3}>
                            <p className="text-sm mb-0">Select End</p>
                            <DatePicker 
                                selected={end} 
                                onChange={(date) => setEnd(date)} 
                            />
                        </Col>
                    
                    </Row>
                )}
            />

            <Container className="ful mt--4 pt-4" fluid>
                
                {data.custom_configuration && data.custom_configuration.length ? <CustomHeader data={data.custom_configuration} /> : '' }

                <Billing data={data} />

                <Categories data={data} />
                <Dispositions data={data} />
                <CallMetrics data={data} />
                <ProgressBars data={data} />
                <CompanyHealth data={data} />
                <SupportTickets data={data} />
                <MatterDocuments data={data} />
                <ESignatures data={data} />
                <TodaysLeads data={data} />


                <div className="pb-6" />
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SupervisorTodaysActivity);
