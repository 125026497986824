import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, FormGroup, Row, UncontrolledTooltip } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import DatePicker from 'react-datepicker';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';
import { getUrlParameter } from "utils/urls";

import PieArea from 'components/charts/PieArea';

const Payments = ({DOMAINS, selected_division}) => {

    const [ loading, setLoading ] = useState(true);

    const [ status, setStatus ] = useState(getUrlParameter('status') || 'all');
    const [ type, setType ] = useState(getUrlParameter('type') || 'all');

    const [ delinquent, setDelinquent ] = useState('all');
    const [ trust, setTrust ] = useState('all');
    const [ paymentMethodExpired, setPaymentMethodExpired ] = useState('all');
    const [ paymentMethodType, setPaymentMethodType ] = useState('all');
    const [ madeBy, setMadeBy ] = useState('all');

    const [ start, setStart ] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [ end, setEnd ] = useState(new Date(new Date().setHours(23, 59, 59, 999)));

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    const onSetStart = useCallback((s) => {
        const _start = parseInt(moment(s).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newEnd = moment(s).endOf('month').toDate();
            setEnd(newEnd)
        }
        setStart(s)
    }, [end])
    
    const onSetEnd = useCallback((e) => {
        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(e).endOf('day').format('X'))

        if(_end > (_start + ( 86400 * 32 ))) {
            const newStart = moment(e).startOf('month').toDate();
            setStart(newStart)
        }
        setEnd(e)
    }, [start])

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV, useLoader) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.sort = { created_at: -1 }

        const _start = parseInt(moment(start).format('X'))
        const _end = parseInt(moment(end).endOf('day').format('X'))

        if(_start > _end) return toast.info(`Search start date cannot be greater than end date.`)
        if(_end > (_start + ( 86400 * 32 ))) return toast.info(`Search range must span no more than 1 month`)

        params.filter.created_at = { $gte: _start, $lt: _end }

        if(status !== 'all') params.filter.status = status;
        if(delinquent !== 'all') params.filter.delinquent = delinquent;
        if(type !== 'all') params.filter.type = type;
        if(trust !== 'all') params.filter.trust = trust;
        if(paymentMethodExpired !== 'all') params.filter.payment_method_expired = paymentMethodExpired;
        if(paymentMethodType !== 'all') params.filter.payment_method_type = paymentMethodType;
        if(madeBy !== 'all') {
            if(madeBy === 'user') {
                params.filter.is_user = true;
            } if(madeBy === 'contact') {
                params.filter.is_contact = true;
            }
        }

        if(useLoader)setLoading(true)
        const query = await api._supervisor.payments(selected_division._id, { ...params, isCSV })
        setLoading(false)

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents, overview: query.data.overview })
        setErr(query.message)
    }), [sizePerPage, start, end, status, delinquent, type, trust, paymentMethodExpired, paymentMethodType, madeBy, selected_division._id])

    useEffect(() => {
        query(null, null, true)
        // eslint-disable-next-line
    }, [start, end, status, delinquent, type, trust, paymentMethodExpired, paymentMethodType, madeBy])

    const columns = [
        {
            dataField: "created_at",
            text: 'Created At',
            headerStyle: { width: 170 },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        {moment.unix(row.created_at).format('M/D/YYYY h:mm A')}
                    </div>
                )
            },
        },
       
        {
            dataField: "status",
            text: 'Status',
            headerStyle: { width: 160 },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    {row.status === 'succeeded' ? (
                        <Badge style={styles.badge} color="gradient-success">{row.status}</Badge>
                    ) : row.status === 'failed' ? (
                        <Badge style={styles.badge} color="gradient-danger">{row.refund_for ? 'REFUND' : row.status}</Badge>
                    ) : row.status === 'refunded' || row.status === 'voided'  ? (
                        <Badge style={styles.badge} color="gradient-warning">Refunded</Badge>
                    )  : row.status === 'chargeback'  ? (
                        <Badge style={styles.badge} color="gradient-danger"> 
                            <i className="fas fa-exclamation-triangle mr-1" />
                            Chargeback
                        </Badge>
                    ) : (
                        <Badge style={styles.badge} color="gradient-info">{row.status}</Badge>
                    )}
                </div>
            )
        },
        {
            dataField: "amount",
            text: 'Amount',
            headerStyle: { width: 130 },

            formatter: (cell, row) => row.name === 'no data' ? '' : formatCurrency(row.amount)
        },
        {
            dataField: "type",
            text: 'Type',
            headerStyle: { width: 120 },
            formatter: (cell, row) => row.name === 'no data' ? '' : <span className="text-capitalize">{row.type.replace('_', ' ')}</span>
        },
        {
            dataField: "trust",
            text: 'Trust',
            headerStyle: { textAlign: 'center', width: 70 },
            headerFormatter: () => {
                return (
                    <span>
                        <i id="archk-payments-trust-icon" className="fa-solid fa-handshake cursor-pointer text-success"></i>
                        <UncontrolledTooltip delay={0} placement="bottom" target="archk-payments-trust-icon">
                            Trust Payment
                        </UncontrolledTooltip>
                    </span>
                )
            },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-center">{row.trust ? <i className="fas fa-check text-success" /> : ''}</div>
            )
        },
        {
            dataField: "delinquent",
            text: 'Delinquent',
            headerStyle: { textAlign: 'center', width: 70 },
            headerFormatter: () => {
                return (
                    <span>
                        <i id="archk-payments-delinquent-icon" className="fa-solid fa-credit-card cursor-pointer text-warning"></i>
                        <UncontrolledTooltip delay={0} placement="bottom" target="archk-payments-delinquent-icon">
                            Delinquent Recurring Payment
                        </UncontrolledTooltip>
                    </span>
                )
            },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-center">{!row.delinquent ? <i className="fas fa-exclamation-circle text-warning" /> : ''}</div>
            )
        },
        {
            dataField: "expired",
            text: 'Expired',
            headerStyle: { textAlign: 'center', width: 70 },
            headerFormatter: () => {
                return (
                    <span>
                        <i id="archk-payments-expired-icon" className="fa-solid fa-hourglass-start cursor-pointer text-danger"></i>
                        <UncontrolledTooltip delay={0} placement="bottom" target="archk-payments-expired-icon">
                            Expired Payment Method
                        </UncontrolledTooltip>
                    </span>
                )
            },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-center">{!row.payment_method_expired ? <i className="fas fa-exclamation-triangle text-danger" /> : ''}</div>
            )
        },
        {
            dataField: "matter",
            text: 'Matter',
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-right">
                    <A href={`${DOMAINS.APP}/billing/-/${row.matter}`}>
                        <ObjectFinder collection="matters" _id={row.matter} />
                    </A>
                </div>
            )
        },

    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Payments'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            // description="All Calls"
            leftColSize={3}
            actionComponent={(
               <div></div>
            )}
        />


        <Container fluid>

           
          <Row>

            <div className="col-auto" style={{width: 250}}>
                <Card className="custom-shadow-1">
                    <CardHeader>
                        <CardTitle className="mb-0 text-uppercase">Payment Filter</CardTitle>
                    </CardHeader>
                
                    <CardBody>
                
                        <FormGroup>
                            <p className="text-sm mb-0">Select Start Date</p>
                            <DatePicker 
                                selected={start} 
                                onChange={(date) => onSetStart(date)} 
                            />
                        </FormGroup>

                        <FormGroup>

                            <p className="text-sm mb-0">Select End Date</p>
                            <DatePicker 
                                selected={end} 
                                onChange={(date) => onSetEnd(date)} 
                            />
                        </FormGroup>

                        <ReactSelect
                            title={"Status"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setStatus(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: 'succeeded', label: 'Succeeded' },
                                { value: 'failed', label: 'Failed' },
                                { value: 'refunded', label: 'Refunded' },
                                { value: 'chargeback', label: 'Charged Back' },
                            ]}
                            value={status}
                        />   

                        <ReactSelect
                            title={"Delinquent Subscription"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setDelinquent(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            value={delinquent}
                        />    

                        <ReactSelect
                            title={"Payment Method Expired"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setPaymentMethodExpired(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            value={paymentMethodExpired}
                        />    
                
                        <ReactSelect
                            title={"Payment Method Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setPaymentMethodType(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: 'cc', label: 'Credit/Debit Card' },
                                { value: 'ach', label: 'ACH' },
                                { value: 'cash', label: 'Cash' },
                            ]}
                            value={paymentMethodType}
                        />    
                
                        <ReactSelect
                            title={"Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setType(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: 'recurring', label: 'Recurring' },
                                { value: 'one_time', label: 'One Time' },
                                { value: 'cash', label: 'Cash' },
                            ]}
                            value={type}
                        />    
                    
                        <ReactSelect
                            title={"Trust"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setTrust(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            value={trust}
                        />    
                
                       
                
                        <ReactSelect
                            title={"Made By"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => setMadeBy(obj.value)}
                            options={[
                                { value: 'all', label: '-' },
                                { value: 'user', label: 'User' },
                                { value: 'contact', label: 'Contact' },
                            ]}
                            value={madeBy}
                        />    
                        
                        </CardBody>
                    </Card>
                
                </div>

                <Col>

                    {loading ? <div className="py-6"><Circle /></div> : (
                        <div>

                            <Card className="custom-shadow-1">
                                <CardHeader>
                                    <CardTitle className="mb-0 text-uppercase">Overview</CardTitle>
                                </CardHeader>
                                <table className="table border bg-white custom-shadow-1">
                                    <tbody>
                                        <tr>
                                            <td>Amount Total</td>
                                            <td className="text-right pr-5">{formatCurrency(tableData.overview.total_amount)}</td>
                                            <td className="pl-5">Amount Succeeded</td>
                                            <td className="text-right">{formatCurrency(tableData.overview.succeeded_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td>Succeeded Standard</td>
                                            <td className="text-right pr-5">{formatCurrency(tableData.overview.succeeded_amount_standard)}</td>
                                            <td className="pl-5">Amount Pending</td>
                                            <td className="text-right">{formatCurrency(tableData.overview.pending_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td>Succeeded Trust</td>
                                            <td className="text-right pr-5">{formatCurrency(tableData.overview.succeeded_amount_trust)}</td>
                                            <td className="pl-5">Amount Failed</td>
                                            <td className="text-right">{formatCurrency(tableData.overview.failed_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td>Failed Count %</td>
                                            <td className="text-right pr-5">
                                                {tableData.overview.total ? ((tableData.overview.failed / tableData.overview.total) * 100).toFixed(2) : '0'}%
                                            </td>
                                            <td className="pl-5">Amount Refunded</td>
                                            <td className="text-right">{formatCurrency(tableData.overview.refunded_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td>Succeeded Count %</td>
                                            <td className="text-right pr-5">
                                                {tableData.overview.total ? ((tableData.overview.succeeded / tableData.overview.total) * 100).toFixed(2) : '0'}%
                                            </td>
                                            <td className="pl-5">Amount Charged Back</td>
                                            <td className="text-right">{formatCurrency(tableData.overview.chargeback_amount)}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </Card>

                            {tableData.overview.succeeded || tableData.overview.failed || tableData.overview.refunded ? (
                                <Row>
                                    <Col md={6}>
                                        <Card className="custom-shadow-1">
                                            <CardHeader>
                                                <CardTitle className='mb-0 text-uppercase'>Succeeded / Failed Count</CardTitle>
                                            </CardHeader>
                                            <PieArea 
                                                classNames="mb-0 border"
                                                type="pie"
                                                colors={['green', 'red', 'teal']}
                                                labels={['Succeeded', 'Failed', 'Refunded']}
                                                dataset={[tableData.overview.succeeded, tableData.overview.failed, tableData.overview.refunded]}
                                            />
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card className="custom-shadow-1">
                                            <CardHeader>
                                                <CardTitle className='mb-0 text-uppercase'>Succeeded / Failed Amount</CardTitle>
                                            </CardHeader>
                                            <PieArea 
                                                labelType="currency"
                                                classNames="mb-0 border"
                                                type="pie"
                                                colors={['green', 'red', 'teal']}
                                                labels={['Succeeded', 'Failed', 'Refunded']}
                                                dataset={[tableData.overview.succeeded_amount, tableData.overview.failed_amount, tableData.overview.refunded_amount]}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            ) : ''}
                            
                            <Card className="card-color card-primary table-fixed table-no-stripes custom-shadow-1">

                                <CardFooter>
                                    <CardTitle className="mb-0 text-uppercase">Payments Charged</CardTitle>
                                </CardFooter>

                                <Table
                                    hideSearch={true}
                                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                                    placeholder="Search Matters..."
                                    query={query}
                                    columns={columns}
                                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                                    totalDocuments={tableData.total_documents}

                                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                                />
                            </Card>
                        </div>
                    )}

                </Col>
            </Row>
    
        </Container>

        </>
    )

}

const styles = {
    badge: {
        width: 120
    }
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(Payments);
