import { connect } from 'react-redux'
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";
import { formatCurrency } from 'utils/currency';

import A from 'components/markup/links/A'

const DashboardOverview = ({ data, DOMAINS }) => {

    const onOpenLink = (link) => {
        window.open(link, "_blank");
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle className='mb-0'>Billing</CardTitle>
            </CardHeader>
    
            <CardBody className='text-center p-0' style={{overflow: 'hidden'}}>
    
                <Row className='mb-0'>
    
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments')}>
                        <h3>Total Charged</h3>
                        <p className='mb-0 display-4'>
                            {formatCurrency(data.payments.total_amount)}
                        </p>
                    </Col>
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=succeeded')}>
                        <h3>Successfully Charged</h3>
                        <p className='mb-0 display-4'>{formatCurrency(data.payments.succeeded_amount)}</p>
                    </Col>
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments')}>
                        <h3>Total Payments</h3>
                        <p className='mb-0 display-4'>{data.payments.total}</p>
                    </Col>
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=succeeded')}>
                        <h3>Added Payment Plans</h3>
                        <p className='mb-0 display-4'>{data.paymentSubscriptions.total}</p>
                    </Col>
                
    
                </Row>
            
                <Row className='mb-0 border-top'>
    
                  
                    <Col md={6} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=succeeded')}>
                        <h3>Percent Succeeded</h3>
                        <p className='mb-0 display-4'>
                            {data.payments.total ? ((data.payments.succeeded / data.payments.total) * 100).toFixed(2) + '%' : '-'}
                        </p>
                    </Col>
                    <Col md={6} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=succeeded')}>
                        <h3>Amount Succeeded</h3>
                        <p className='mb-0 display-4'>
                            {data.payments.total_amount ? ((data.payments.succeeded_amount / data.payments.total_amount) * 100).toFixed(2) + '%' : '-'}
                        </p>
                    </Col>
    
                </Row>
            
                <Row className='border-top mb-0'>
    
                    {/* <Col className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=succeeded')}>
                        <h3>Partial Payments</h3>
                        <p className='mb-0 display-4'>{formatCurrency(data.payments.partial_amount)}</p>
                        <p className='text-sm mb-0'>{data.payments.partial} Total</p>
                    </Col> */}
                     <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=pending')}>
                        <h3>Pending Payments</h3>
                        <p className='mb-0 display-4'>{formatCurrency(data.payments.pending_amount)}</p>
                        <p className='text-sm mb-0'>{data.payments.pending} Total</p>
                    </Col>
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=failed')}>
                        <h3>Failed Payments</h3>
                        <p className='mb-0 display-4'>{formatCurrency(data.payments.failed_amount)}</p>
                        <p className='text-sm mb-0'>{data.payments.failed} Total</p>
                    </Col>
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=refunded')}>
                        <h3>Refunded Payments</h3>
                        <p className='mb-0 display-4'>{formatCurrency(data.payments.refunded_amount)}</p>
                        <p className='text-sm mb-0'>{data.payments.refunded} Total</p>
                    </Col>
                   
    
                    <Col md={3} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?status=chargeback')}>
                        <h3>Charge Backs</h3>
                        <p className='mb-0 display-4'>{formatCurrency(data.payments.chargeback_amount)}</p>
                        <p className='text-sm mb-0'>{data.payments.chargeback} Total</p>
                    </Col>
                
                </Row>
            
                <Row className='border-top mb-0'>
    
                    <Col md={4} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?type=one_time')}>
                        <h3>One Time Payments</h3>
                        <p className='mb-0 display-4 '>
                            <div>{data.payments.one_time}</div>
                            <small className='mt--2 d-block'><small>{data.payments.one_time ? ((data.payments.one_time / data.payments.total) * 100).toFixed(2): '0'}%</small></small>
    
                        </p>
                    </Col>
    
                    <Col md={4} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?type=recurring')}>
                        <h3>Recurring Payments</h3>
                        <p className='mb-0 display-4 '>
                            <div>{data.payments.recurring}</div>
                            <small className='mt--2 d-block'><small>{data.payments.recurring ? ((data.payments.recurring / data.payments.total) * 100).toFixed(2): '0'}%</small></small>
                        </p>
                    </Col>
                    <Col md={4} className='border-right py-3 cursor-pointer' onClick={() => onOpenLink('/payments?type=cash')}>
                        <h3>Cash Payments</h3>
                        <p className='mb-0 display-4 '>
                            <div>{data.payments.cash}</div>
                            <small className='mt--2 d-block'><small>{data.payments.cash ? ((data.payments.cash / data.payments.total) * 100).toFixed(2): '0'}%</small></small>
                        </p>
                    </Col>
            
                </Row>
    
            </CardBody>
        </Card>
    )
}


const mapStateToProps = state => {
    return {
        DOMAINS: state.load.DOMAINS,

    };
};

export default connect(mapStateToProps, '')(DashboardOverview);