import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Badge, Card, CardFooter, CardTitle, Container } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';


import Circle from 'components/markup/loading/Circle';

const ExpiredMethods = ({DOMAINS, selected_division}) => {

    const [ loading, setLoading ] = useState(true);

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const query = useCallback((params, isCSV, useLoader) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(useLoader)setLoading(true)
        const query = await api._supervisor.expiredMethods(selected_division._id, { ...params, isCSV })
        setLoading(false)

        if(query.data) return setTableData({ data: query.data.documents, total_documents: query.data.total_documents, overview: query.data.overview })
        setErr(query.message)
    }), [sizePerPage, selected_division._id])

    useEffect(() => {
        query(null, null, true)
        // eslint-disable-next-line
    }, [])


    const columns = [
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <A href={`${DOMAINS.APP}/billing/-/${row.matter}?tab=recurring`}>
                            <ObjectFinder collection="matters" _id={row.matter} />
                        </A>
                    </div>
                )
            },
        },
       
        {
            dataField: "backup_method",
            text: 'Amount',
            headerStyle: { textAlign: 'center' },

            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className='text-center'>
                    {row.backup_method ? <Badge color="info">Backup</Badge> : row.primary_method ? <Badge color="success">Primary</Badge> : ''}
                </div>
            )
        },
        {
            dataField: "type",
            text: 'Type',
            headerStyle: { },

            formatter: (cell, row) => row.name === 'no data' ? '' : <span className='text-uppercase font-weight-bold text-dark'>{row.type}</span>
        },
        {
            dataField: "payments_failed_consecutive",
            text: 'Consecutive Fails',
            headerStyle: { textAlign: 'center'},

            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-center'>{row.payments_failed_consecutive}</div>
        },
        {
            dataField: "last_payment_status_message",
            text: 'Failed Message',
            headerStyle: { textAlign: 'right'},

            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-right'>{row.last_payment_status_message}</div>
        },

        {
            dataField: "expires_unix",
            text: 'Expired',
            headerStyle: { width: 160,  textAlign: 'right' },

            formatter: (cell, row) => row.name === 'no data' ? '' : <div className='text-right'>{moment.unix(row.expires_unix).format('MM/DD/YYYY')}</div>
        },

    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Expired Methods'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={6}
        />


        <Container fluid>

            {loading ? <div className="py-6"><Circle /></div> : (
                <div>

                    <Card className="card-color card-primary table-fixed table-no-stripes custom-shadow-1">

                        <CardFooter>
                            <CardTitle className="mb-0 text-uppercase">All Expired Payment Methods</CardTitle>
                        </CardFooter>

                        <Table
                            hideSearch={true}
                            tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                            placeholder="Search Matters..."
                            query={query}
                            columns={columns}
                            data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                            totalDocuments={tableData.total_documents}

                            onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                        />
                    </Card>
                </div>
            )}

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(ExpiredMethods);
