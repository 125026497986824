import hasPrivilege from 'utils/hasPrivilege'

import ViewPermission from 'components/markup/errors/ViewPermission';

import TodaysActivity from '../pages/todays_activity';
import UserActivity from '../pages/user_activity';
import UserLinearity from '../pages/user_linearity';
import TextThreads from '../pages/text_threads';
import MonthToDate from '../pages/month_to_date';
import PipelineBreakdown from '../pages/pipeline_breakdown';
import RevenueProjections from '../pages/revenue_projections';
import MattersOverdue from '../pages/matters_overdue';
import MatterAlerts from '../pages/matter_alerts';
import PhoneCalls from '../pages/phone_calls';
import PhoneCallsUsers from '../pages/phone_calls_users';
import PhoneCallsUser from '../pages/phone_calls_users/User';
import PhoneCallsDivision from '../pages/phone_calls_users/Division';
import PhoneCallsView from '../pages/phone_calls/View';
import SupportTickets from '../pages/support_tickets';
import Notes from '../pages/notes';
import OutstandingItems from '../pages/outstanding_items';
import OutstandingItemsCalls      from '../pages/outstanding_item_calls';

import Payments      from '../pages/payments';
import PaymentSubscriptions      from '../pages/payment_subscriptions';
import DelinquentMatters      from '../pages/delinquent_matters';
import ExpiredMethods      from '../pages/expired_methods';

import Problems from '../pages/problems';
import Leads from '../pages/leads';
import Pipeline from '../pages/pipeline';

import CommunicationLive      from '../pages/communication/Live';
import CommunicationUserOffers      from '../pages/communication/UserOffers';
import CommunicationSelectUser      from '../pages/communication/SelectUser';
import CommunicationUnreturned      from '../pages/communication/Unreturned';
import CommunicationVoicemail      from '../pages/communication/Voicemail';
import CommunicationQueue      from '../pages/communication/Queue';

import HistoricalDaily      from '../pages/historical/Daily';

import SignatureAnalytics      from '../pages/signatures/Analytics';

import Tasks      from '../pages/tasks';
import Events      from '../pages/events';
import EventCalls      from '../pages/event_calls';
import Bookings      from '../pages/bookings';
import MatterDocumentUploads      from '../pages/matter_document_uploads';
import Uploads      from '../pages/uploads';
import Voicemails      from '../pages/voicemails';


const routes = () =>  [

    {
        path: "/month_to_date",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.MONTH_TO_DATE') ? MonthToDate : ViewPermission,
    },
    {
        path: "/pipeline_breakdown",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PIPELINE_BREAKDOWN') ? PipelineBreakdown : ViewPermission,
    },
    {
        path: "/revenue_projections",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.REVENUE_PROJECTIONS') ? RevenueProjections : ViewPermission,
    },
    {
        path: "/matters_overdue",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.MATTERS_OVERDUE') ? MattersOverdue : ViewPermission,
    },
    {
        path: "/matter_alerts",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.MATTER_ALERTS') ? MatterAlerts : ViewPermission,

    },
    {
        path: "/phone_calls_users",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS') ? PhoneCallsUsers : ViewPermission,
    },
    {
        path: "/phone_calls_user/division",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS') ? PhoneCallsDivision : ViewPermission,
    },
    {
        path: "/phone_calls_user/:user",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS') ? PhoneCallsUser : ViewPermission,
    },
    {
        path: "/calls",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS') ? PhoneCalls : ViewPermission,
    },
    {
        path: "/calls/:call_id",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS') ? PhoneCallsView : ViewPermission,

    },
    {
        path: "/support_tickets",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SUPPORT_TICKETS') ? SupportTickets : ViewPermission,
    },
    {
        path: "/outstanding_items",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SUPPORT_TICKETS') ? OutstandingItems : ViewPermission,
    },

    {
        path: "/communication",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? CommunicationLive : ViewPermission,
    },
    {
        path: "/communication/users",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? CommunicationSelectUser : ViewPermission,
    },
    {
        path: "/communication/unreturned",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? CommunicationUnreturned : ViewPermission,
    },
    {
        path: "/communication/voicemail",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? CommunicationVoicemail : ViewPermission,
    },
    {
        path: "/communication/user/:user",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? CommunicationUserOffers : ViewPermission,
    },
    {
        path: "/communication/queue/:call_queue",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? CommunicationQueue : ViewPermission,
    },
    
    {
        path: "/signatures",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SIGNATURES') ? SignatureAnalytics : ViewPermission,
    },
    {
        path: "/signatures/signed",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SIGNATURES') ? SignatureAnalytics : ViewPermission,
    },
    {
        path: "/signatures/users",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SIGNATURES') ? SignatureAnalytics : ViewPermission,
    },
  
    {
        path: "/tasks",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TASKS') ? Tasks : ViewPermission,
    },
    {
        path: "/outstanding_items_calls",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TASKS') ? OutstandingItemsCalls : ViewPermission,
    },
    {
        path: "/events",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.EVENTS') ? Events : ViewPermission,
    },
    {
        path: "/event_calls",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.EVENTS') ? EventCalls : ViewPermission,
    },
    {
        path: "/bookings",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.EVENTS') ? Bookings : ViewPermission,
    },
    {
        path: "/matter_document_uploads",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.DOCUMENTS') ? MatterDocumentUploads : ViewPermission,
    },
    {
        path: "/voicemails",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.VOICEMAIL') ? Voicemails : ViewPermission,
    },
    {
        path: "/notes",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_NOTES') ? Notes : ViewPermission,
    },
    
    {
        path: "/problems",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PROBLEMS') ? Problems : ViewPermission,
    },



    {
        path: "/uploads",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.UPLOADS') ? Uploads : ViewPermission,

    },
    {
        path: "/leads",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.LEADS') ? Leads : ViewPermission,
    },


    {
        path: "/payments",
        component:  hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.BILLING') ? Payments : ViewPermission,
    },
    {
        path: "/subscriptions",
        component:  hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.BILLING') ? PaymentSubscriptions : ViewPermission,
    },

    {
        path: "/delinquent",
        component:  hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.BILLING') ? DelinquentMatters : ViewPermission,
    },
   
    {
        path: "/expired_methods",
        component:  hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.BILLING') ? ExpiredMethods : ViewPermission,
    },
   




    
    {
        path: "/text_threads",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TEXT_THREADS') ? TextThreads : ViewPermission,
    },
    {
        path: "/user_activity",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY') ? UserActivity : ViewPermission,
    },
    {
        path: "/user_linearity",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_EFFECTIVENESS') ? UserLinearity : ViewPermission,
    },
    {
        path: "/pipeline",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY') ? Pipeline : ViewPermission,
    },
   
    {
        path: "/historical/daily",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.HISTORICAL') ? HistoricalDaily : ViewPermission,
    },
  
    {
        path: "/",
        component: hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TODAYS_ACTIVITY') ? TodaysActivity : ViewPermission,
    },
   
]

export default routes;
