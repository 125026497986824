import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardHeader, CardTitle, Col, Container, Modal, Row, CardBody } from "reactstrap";


import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';
import formatText from 'utils/formatText';

import api from 'api';

import ModalToggler from 'components/functional/modals/Toggler';

import Details from './Details'
import Overview from './Overview'
import ScoreCard from './ScoreCard'


const MattersAll = ({viewing_user, match}) => {
    const [callLog, setCallLog] = useState(null)
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async () => {
        const query = await api.calls.findById(match.params.call_id)
        if(query.data) {
            query.data.call_legs.sort((a, b) => a.start < b.start ? -1 : 1)
            query.data.events.sort((a, b) => a.start < b.start ? -1 : 1)
            return setCallLog(query.data)
        }
        if(query.success) return setCallLog({})
        return setErr(query.message)
    }, [match.params.call_id])



    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} />
    if(!callLog) return <Circle />
    if(!callLog._id) return (
        <div className="alert alert-info text-center">
            <i className="fas fa-info-circle" />{' '}
            This Call You Are Looking For Could Not Be Found
        </div>
    )

    return (

        <>

        <Helmet>
            <title>Call Log</title>
            <meta name="description" content="Call Log" />
        </Helmet>

        <HeaderNavigation 
            title="View Call Log"
            actionComponent={(
                <p className="text-sm mb-0">{moment.unix(callLog.created_at).format("MM/DD/YYYY h:mm A")}</p>
            )}
        />

        <Container fluid>

           <Overview callLog={callLog} />
           
           <Row>
                <Col>
                    <Details callLog={callLog} />
                </Col>
                
                {/* <div className="col-auto" style={{width: 400}}>
                    <ScoreCard callLog={callLog} />
                </div> */}
           </Row>


        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
